
const introduce = {

  // 激活节点样式
  activeElement: (list, active, EventBus) => {
    if (active === 'all') {
      return;
    }

    // 将active的节点高亮
    // 其余隐藏置灰
    for (const ele in list) {
      list[ele].style.splice(0);
      if (ele === active) {
        list[ele].style.push('iff-hightLight');
        if (EventBus && ele === 'lwzt') {
          EventBus.$emit('introduce-hightLight-lwzt');
        }
      } else {
        list[ele].style.push('iff-hide');
        if (EventBus && ele === 'lwzt') {
          EventBus.$emit('introduce-hide-lwzt');
        }
      }
    }
  },
  // 清空节点样式:
  clearStyle: (list, EventBus) => {
    for (const ele in list) {
      list[ele].style.splice(0);
    }
    if (EventBus) {
      EventBus.$emit('introduce-clear-lwzt');
    }
  }

};

module.exports = introduce;
