<!--
 * @Author: zxf
 * @Date: 2023-10-16 15:19:15
 * @LastEditors: zxf
 * @LastEditTime: 2023-10-16 16:12:22
 * @Description: file content
-->
<template>
  <div class="u-sub-title">
    <!-- <div class="u-sub-icon" /> -->
    <span class="u-sub-title__header">{{ innerText }}</span>
  </div>
</template>

<script>
export default {
  name: 'SubTitle2',
  props: {
    innerText: {
      type: String,
      default: '智慧机器人平台'
    }
  }
};
</script>

<style scoped lang="scss">
.u-sub-title{
  display: flex;
  align-items: flex-start;
  width: 380px;
  height: 28px;
  margin-top: 4px;
  background: url(/images/monitor/subTitle2.png) no-repeat left bottom / 380px 30px;

  &__header{
    padding-left: 25px;
    font-family: "HYYaKuHei-85J";
    font-size: 16px;
    line-height: 17px;
    letter-spacing: 2px;
    color: #FFF;
  }
}

.u-sub-icon{
  width: 6px;
  height: 6px;
  margin: auto 10px;
  box-shadow: 0 0 7px 2px #00fefa80;
  background: #3DFBFF;
}

</style>
