<template>
  <div class="monitor_fireWarn-wrap">
    <!-- 左边 -->
    <div class="firewarn-left">
      <!-- 消防设施 -->
      <div class="firewarn_build">
        <div class="">
          <sub-title inner-text="火警列表" />
        </div>
        <div style="position: relative">
          <div
            class="hjlb-tj"
            style="display: flex"
          >
            <img
              src="/monitor/images/hj/火灾.png"
              style="height: 20px"
              class="hjlb-tj-img"
            >
            <div
              class="hjlb-tj-text"
              style="margin-top: 2px"
            >
              {{ hjxxList.length }}处
            </div>
          </div>
        </div>

        <hjlbTJ
          :bjzt-page-list="bjztPageList"
          :hjxx-list="hjxxList"
          :slxx-mx-list="slxxMxList"
          :slxx-id="slxxId"
          @handlechangeHzSeleteId="handlechangeHzSeleteId"
        />
      </div>

      <!-- 确认事项 -->
      <div class="firewarn_build">
        <div class="">
          <sub-title inner-text="预案执行" />
        </div>
        <div v-if="kzqList.length <= 0">
          <confirmPart
            control-main-status="auto"
            xxqr="2"
            :done="6"
          />
        </div>
        <!--        <img-->
        <!--          v-if="kzqList.length > 0"-->
        <!--          style="width: 456px;height:303px;margin-top: 30px;"-->
        <!--          src="/monitor/images/comfirmPart/p5.png"-->
        <!--        >-->
        <confirmPart
          v-if="kzqList.length >0"
          :control-main-status="kzqZt"
          :xxqr="xxqr"
        />
      </div>
    </div>

    <!-- 中间 -->
    <div class="firewarn-middle">
      <!-- 火灾视频 -->
      <div
        class="firewarn_build"
        style="position: relative"
      >
        <!-- 火灾视频顶上一栏 -->
        <div

          class=""
        >
          <!-- <hzTtileTJ
            :inner-text="baseInfo.hzdd+'-'+
              baseInfo.bjmc +' 正在发生火灾1'"
          /> -->
          <hzTtileTJ
            :inner-text="getHzddTitle()"
          />
        </div>

        <div
          v-if="baseInfo.hzdd"
          style="position: absolute;right:90px;top:27px;width: 90px;height: 30px;"
          @click="historyHj"
        >
          <img
            style="width: 80px;height: 30px;"
            src="/images/screen/xq/lsBj.png"
          >
        </div>
        <div>
          <img
            src="/monitor/images/hj/消音.svg"
            style="position: absolute;right:20px;top:27px"
            @click="stopAudio()"
          >

          <!-- 控制是否展示视频 -->
          <div v-if="showHjVideo">
            <hjVideo
              class="firewarn_movie-img"
              :bjxx-id="baseInfo.gzsbh"
            />
          </div>

          <div
            v-if="!showHjVideo"
            class="firewarn_build"
            style="height: 283px;"
          >
            <div class="">
              <sub-title :inner-text="baseInfo.dwmc" />
            </div>
            <div
              id="BMap-container"
              class="firewarn_build-map"
              style="width: 720px;height:300px"
            />
          </div>
        </div>
      </div>

      <!-- 楼层平面图 -->
      <div
        class="firewarn_build"
      >
        <div class="">
          <sub-title inner-text="楼层平面图" />
        </div>
        <FloorPlanImg :bjxx="bjztList[0]" />
      </div>
    </div>

    <!-- 右边 -->
    <div class="firewarn-right">
      <div style="position: relative">
        <img
          src="/monitor/images/hj/KHCFDC_缩小.svg"
          style="width: 33px; height: 40px;margin-left: 10px;position: absolute;right:0px;top:-60px"
          @click="hideHj()"
        >
      </div>

      <div
        v-if="!showHjVideo"
        class="firewarn_build"
      >
        <div class="">
          <sub-title inner-text="火灾信息" />
        </div>
        <HzxxTJNoVideo
          :base-info="baseInfo"
        />
      </div>

      <!-- 地图 -->
      <div
        v-if="showHjVideo"
        class="firewarn_build"
        style="height: 283px;"
      >
        <div class="">
          <sub-title :inner-text="baseInfo.dwmc" />
        </div>
        <div
          id="BMap-container"
          class="firewarn_build-map"
        />
        <hzxxTJ
          class="hzxxTJ-position"
          :base-info="baseInfo"
        />
      </div>

      <!-- 消防设施 -->
      <div
        class="firewarn_build"
        :style="kzqList.length > 0?'height: 261px':'height: 649px'"
      >
        <div class="">
          <sub-title inner-text="消防设施状态" />
        </div>
        <xfssTJ :bjzt-page-list="bjztPageList" />
      </div>

      <!-- 控制器 -->
      <div
        v-if="kzqList.length >0"
        class="firewarn_build "
        style="height: 368px"
      >
        <div class="">
          <sub-title inner-text="控制器" />
          <div
            class="kzq-background"
            :class="getKzqBackgroundClass()"
          >
            <div
              v-for="(item, index) in kzqList"
              :key="index"
              class="kzq-item"
              :class="getKzqIconClass(item.kzqmc)"
            >
              <span
                class="kzq-text"
                style="display: flex"
              >
                <div style="margin-top: 5px;margin-left: 5px;margin-right: 10px">{{ item.kzqmc }}</div>
                <el-switch
                  v-model="item.ztFlag"
                  active-color="#134bcb"
                  inactive-color="#783000"
                  style="margin-top: 2px"
                  @change="switchChange(item)"
                />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SubTitle from '../components/SubTitleTJ.vue';
import FloorPlanImg from './floorPlanImg.vue';
import { warnService } from './fireWarn.service';
import { nplService } from '@/features/virtual/npl/npl.service';
import hzxxTJ from './hzxxTJ.vue';
import hzTtileTJ from './hzTtileTJ.vue';
import xfssTJ from './xfssTJ.vue';
import hjlbTJ from './hjlbTJ.vue';
import confirmPart from './confirmPart.vue';
import axios from 'axios';
import EventBus from './../../../event-bus';
import HzxxTJNoVideo from './hzxxTJNoVideo.vue';
import hjVideo from './video/hjVideo.vue';
import detail from './xq/detail.vue';
// import { zhxfService } from '../../virtual/chart/zhxf.service';
import { lshjService } from './xq/LshjService.js';
export default {
  name: 'FireWarn',
  components: {
    SubTitle,
    FloorPlanImg,
    hzxxTJ,
    hzTtileTJ,
    xfssTJ,
    hjlbTJ,
    confirmPart,
    HzxxTJNoVideo,
    hjVideo
  },
  props: {
    slxxId: {
      type: String,
      default: null
    },
    // 发生火灾的受理信息列表
    hjxxList: {
      type: Array,
      default: null
    }
  },

  data () {
    return {
      layerid: null,
      bjztPage: 1,
      bjztPageList: null,
      bjztList: [],
      ldxxDetailVOList: [], // 火灾的联动信息
      baseInfo: {
        dwmc: '',
        hzdd: '',
        bjmc: '',
        dzLng: null,
        dzLat: null,
        fxsj: null,
        xffzr: null,
        zbdh: null,
        xxqr: null,
        azwz: null,
        gzsbhmc: null,
        gzsbh: null,
        fhfqMc: ''
      },
      slxxTimes: null,
      kzqList: [
        // { kzqId: '1010025006', kzqmc: '送风机', zt: '0', ztFlag: false },
        // { kzqId: '1010025007', kzqmc: '消防电梯', zt: '0', ztFlag: false },
        // { kzqId: '1010025008', kzqmc: '消防水泵', zt: '1', ztFlag: false }
      ],
      hzsxList: [], // 火灾事项列表
      controlMainStatus: 'Manual',
      xxqr: null,
      hzImg: '/monitor/images/hj/hz_mr.png',
      slxxMxList: [],
      videoExist: false // 判断是否有视频接入，如果有则展示视频，如果没有则展示静态图
    };
  },
  computed: {
    kzqZt () {
      // 更新控制器状态
      // 遍历kzqList 获取kzqmc为控制器的对象
      const kzq = this.kzqList ? this.kzqList.find(item => item.kzqmc === '控制器') : null;
      if (!kzq) return 'Manual';
      return kzq.zt === 1 ? 'auto' : 'Manual';
    },
    showHjVideo () {
      // 控制是否展示火警视频
      // 如果当前用户单位是清远康大、颐和等，则不展示火警视频，使用地图进行替换，然后火灾信息扩大放在原地图位置上
      const lwdw = this.$HMStoreService.getUserinfo().dept.deptCode;
      if (lwdw === '2c9d80938a6e461c018ab24f59316041' || lwdw === '2c9d80938a6e461c018ab24e007b6040' || lwdw === '2c9d80938a6e461c018ab0c975a65fa0') {
        return false;
      }
      return true;
    }
  },

  methods: {

    getHzddTitle () {
      if (this.baseInfo.hzdd) {
      this.slxxMxList = this.hjxxList.find(item => item.id === this.slxxId).slxxMxList;
      if (this.slxxMxList && this.slxxMxList.length > 0 && this.slxxMxList[0].fhfqMc) {
          this.baseInfo.fhfqMc = this.slxxMxList[0].fhfqMc;
      }

        return this.baseInfo.hzdd + '【' + this.baseInfo.fhfqMc + this.baseInfo.bjmc + '】正在发生火灾';
      }
      return '载入中...';
    },
    historyHj () {
      console.log('============historyHj', this.slxxId);
      const whidth = '1650px';
      const height = '900px';
      lshjService.queryHzxx(this.slxxId).then(res => {
        if (res.data) {
          const data = res.data;
          const slxxDetail = data;
          console.log('queryHzxx', data);
      this.layerid = this.$layer.iframe({
        content: {
          content: detail,
          parent: this,
          data: {
            info: {
              slxxInfo: slxxDetail,
              iframeWindow: { whidth: whidth, height: height }
            }
          }// props
        },
        area: [whidth, height],
        title: '历史报警详情',
        maxmin: false,
        shade: false,
        scrollbar: false,
        shadeClose: false,
        cancel: () => {
          // console.log('关闭iframe');
        }
      });
        }
      });
    },
    getKzqList () {
      const _this = this;
      warnService.queryKzqList({ ssdw: this.baseInfo.ssdw }).then(rsp => {
        _this.kzqList = rsp.data.rows;
        console.log('kzqList', _this.kzqList);
      }).catch(error => {
        if (error.response) {
          console.log('服务异常:', error.response);
        } else if (error.request) {
          console.log('网络异常:', error.request);
        } else {
          console.log('请求异常:', error.message);
        }
      }); ;
    },
    // 消音
    stopAudio () {
      console.log('handleCancleOme');
      // 调用父组件的消音
      this.$emit('handleCancleOme', false, true);
    },
    getDetail () {
      const _this = this;
      console.log('getDetail');
      warnService.getDetail(this.slxxId).then(rsp => {
        console.log('rsp.data', rsp.data);
        if (rsp.data.gzsbhmc.indexOf('模拟火灾') > -1) {
          sessionStorage.setItem('mnhj', true);
        } else {
          sessionStorage.setItem('mnhj', '');
        }
        _this.baseInfo.dwmc = rsp.data.lwdwDetatilsVO.dwmc;
        _this.baseInfo.hzdd = rsp.data.lwdwDetatilsVO.dz;
        _this.baseInfo.dzLng = rsp.data.lwdwDetatilsVO.dzLng;
        _this.baseInfo.dzLat = rsp.data.lwdwDetatilsVO.dzLat;
        _this.baseInfo.fxsj = rsp.data.scbjsj;
        _this.baseInfo.xffzr = rsp.data.lwdwDetatilsVO.xfaqzrrmc;
        _this.baseInfo.xxqr = rsp.data.xxqr;
        _this.baseInfo.azwz = rsp.data.bjxxInfo.azwz;
        _this.baseInfo.bjmc = rsp.data.bjxxInfo.bjmc;
        _this.baseInfo.ssdw = rsp.data.ssdw;
        _this.baseInfo.gzsbh = rsp.data.gzsbh;

        console.log('xxqr', _this.baseInfo.xxqr);
        _this.kzqList = rsp.data.kzqDetailVOList;
        console.log('kzqList', _this.kzqList);

        const xffzrDh = rsp.data.lwdwDetatilsVO.xfaqzrrdh;
        if (xffzrDh) _this.baseInfo.xffzr += `（${xffzrDh}）`;
        _this.baseInfo.zbdh = rsp.data.lwdwDetatilsVO.xfkzsdh;
        _this.switchMap(_this.baseInfo.dzLng, _this.baseInfo.dzLat);
      });
    },
    getDynamicData () {
      const _this = this;
      warnService.getDynamicData(this.slxxId).then(resp => {
        _this.hzsxList = resp.data.qrsxDetailVOList && resp.data.qrsxDetailVOList.length > 0 ? resp.data.qrsxDetailVOList : []; // 火灾事项列表
        _this.ldxxDetailVOList = resp.data.ldxxDetailVOList; // 火灾的联动信息
        _this.bjztList = resp.data.bjxxBasicVOList; // 部件状态列表
        console.log('ldxxDetailVOList', _this.ldxxDetailVOList);
        console.log('bjztList', _this.bjztList);
        this.bjztPage = 1;
        this.switchBjzt();
        if (this.clock) {
          clearInterval(this.clock);
        }
        this.clock = setInterval(this.switchBjzt, 5000);
      });
    },
    switchBjzt () {
      let resultList = [];

      // 颐和豪庭等 ，调整逻辑，首先筛选火警信息固定放在前面，然后展示联动信息
      if (!this.showHjVideo) {
        const hzBjztList = this.bjztList.filter(item => item.bjzt === '10');
        // 前30个联动信息
        const ldxxList = this.ldxxDetailVOList.slice(0, 30);
        resultList = hzBjztList.concat(ldxxList);
      } else {
        resultList = this.bjztList;
      }

      const pageSize = 15; // 分页大小
      const pageTotal = Math.ceil(resultList.length / pageSize);
      this.bjztPageList = resultList.slice((this.bjztPage - 1) * pageSize, this.bjztPage * pageSize);
      this.bjztPage = this.bjztPage + 1 > pageTotal ? 1 : this.bjztPage + 1;
    },
    initMap () {
      /* global BMapGL */
      const styleOptions = {
        styleJson: [
          {
            featureType: 'background',
            elementType: 'all',
            stylers: {
              color: '#001839'
            }
          }
        ]
      };

      const map = this.map = new BMapGL.Map('BMap-container');
      map.centerAndZoom(new BMapGL.Point(113.351585, 23.107915), 14); // 初始化地图,设置中心点坐标和地图级别
      map.enableScrollWheelZoom(true); // 开启鼠标滚轮缩放
      map.setMapStyleV2(styleOptions);
      this.slxxTimes = setInterval(this.getKzqList, 2 * 1000);

      // 设置地图样式
      axios.get('/monitor/map_config.json').then(resp => {
        console.log(resp);
        const json = resp.data;
        map.setMapStyleV2({ styleJson: json });
      });
    },
    switchMap (lng, lat) {
      if (!lng || !lat) return;
      const map = this.map;
      const point = new BMapGL.Point(lng, lat);
      map.setCenter(point, {
        noAnimation: false,
        callback: () => {
          // map.setTilt(50);// 设置倾斜角度
          // const infoWindow = new BMapGL.InfoWindow(`地址：${_robot.dz}`, {
          //   width: 250, // 信息窗口宽度
          //   height: 15, // 信息窗口高度
          //   title: _robot.name // 信息窗口标题
          // }); // 创建信息窗口对象
          // map.openInfoWindow(infoWindow, this.map.getCenter()); // 打开信息窗口
          map.addOverlay(new BMapGL.Marker(point));
        }
      });
    },
    // 隐藏火警
    hideHj () {
      EventBus.$emit('controlShowHj', false);
      // 关闭弹窗
      if (this.layerid) {
        this.$layer.close(this.layerid);
      }
    },
    switchChange (_item) {
      console.log(_item);
      let str = null;
      // var oldZt = _item.zt;
      if (_item.zt === '0' || _item.zt === 0) {
        _item.zt = '1';
        _item.ztFlag = true;
        str = _item.kzqmc + '开启成功！';
      } else {
        _item.zt = '0';
        _item.ztFlag = false;
        str = _item.kzqmc + '已关闭！';
      }
      const param = { kzqId: _item.kzqId, td: _item.td, zt: _item.zt, uuid: _item.uuid };
      warnService
        .change(param)
        .then(resp => {
          console.log(str);
          nplService.play(str, () => {
          });
        }, 50, 100)
        .catch(error => {
          console.log(error);
          str = '控制器操作异常';
        });
    },
    getKzqIconClass (kzqmc) {
      if (kzqmc === '送风机') {
        return 'icon_sfj';
      } else if (kzqmc === '排烟风机') {
        return 'icon_pyfj';
      } else if (kzqmc === '消防电梯') {
        return 'icon_xfdt';
      } else if (kzqmc === '水泵') {
        return 'icon_sb';
      } else if (kzqmc === '控制器') {
        return 'icon_kzq';
      }
    },
    getKzqBackgroundClass () {
      if (this.kzqList.length === 5) {
        return 'kzq-background1';
      }
    },
    handlechangeHzSeleteId (newValue) {
      console.log('Selected value has changed:', newValue);
      // 修改父组件的slxxId
      this.$emit('updateSlxxId', newValue);
      // 重新获取火灾信息
      this.getDetail();
    },
    init () {
      clearInterval(this.timer);
      clearInterval(this.slxxTimes);
      this.getDetail();
      this.getDynamicData();
      this.timer = setInterval(this.getDynamicData, 1000 * 60);
    }
  },
  watch: {
    // 当父组件的slxxId变化时，会改变选择火灾的值
    slxxId (newVal, oldVal) {
      this.hzSeleteId = newVal;
      // this.init();
      // 关闭弹窗
      if (this.layerid) {
        this.$layer.close(this.layerid);
      }
    },
    hjxxList (newVal, oldVal) {
      const hj = this.hjxxList.find(item => item.id === this.slxxId);
      console.log('hj', hj);
      this.xxqr = hj.xxqr;
    }
  },
  mounted () {
    const SCREEN_HZ_IMG = sessionStorage.getItem('SCREEN_HZ_IMG');

    if (SCREEN_HZ_IMG && SCREEN_HZ_IMG === 'TRUE') {
      this.hzImg = '/monitor/images/hj/hz_tj.png';
    }
    this.initMap();
    this.getDetail();
    this.getDynamicData();
    this.timer = setInterval(this.getDynamicData, 1000 * 60);

    // 初始化选择火灾的值;
    this.hzSeleteId = this.slxxId;
  },
  beforeDestroy () {
    clearInterval(this.timer);
    clearInterval(this.slxxTimes);
  }
};
</script>

<style scoped lang="scss">
@import '../../virtual/chart/chart.scss';
@import './kzqBtn.scss';

.monitor_fireWarn-wrap {
  width: 100%;
  height: 100%;
  background-color: #0B1329;
}

.firewarn-left {
  float: left;
  width: 518px;
  height: 100%;
  margin-left: 20px;
  margin-top: 20px;
}

.firewarn_build {
  box-sizing: border-box;
  width: 100%;
  height: 466px;
  background: #08193F;
  border-radius: 4px;

  padding: 20px;
  margin-bottom: 20px;

  &-map{
    width: 478px;
    height: 206px;
  }

  &-msg {
    display: flex;
    justify-content: space-between;
    margin-top: 32px;

    &-title {
      font-family: MicrosoftYaHei;
      font-size: 20px;
      line-height: 20px;
      color: #fcfeff;
    }

    &-text {
      font-family: MicrosoftYaHei;
      font-size: 20px;
      line-height: 20px;
      color: #5afff7;
    }
  }
}

.firewarn_movie {
  box-sizing: border-box;
  width: 477px;
  height: 429px;
  padding: 3%;
  margin: 18px 0 0 0;
  border: solid 1px #2667ae;
  background-color: #0a365c;
}

.firewarn-middle{
  float: left;
  width: 804px;
  height: 100%;
  margin-left: 20px;
  margin-top: 20px;
}

.firewarn_plan_build {
  width: 813px;
  height: 66px;
  font-family: MicrosoftYaHei;
  font-size: 30px;
  line-height: 66px;
  color: #5afff7;
  text-align: center;
  background-color: #0a365c;
}

.firewarn_plan_ya {
  position: relative;
  box-sizing: border-box;
  width: 817px;
  height: 300px;
  padding: 2%;
  margin: 19px 0 0 0;
  border: solid 1px #2667ae;
  background-color: #0a365c;

  &__inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 2%;
    list-style-type: none;
  }

  &__item {
    display: flex;
    width: calc(50% - 40px);
    border: solid 1px #ffbb20;
    color: #ffbb20;
  }

  &__index {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 46px;
    border-right: 1px solid #ffbb20;
    font-size: 26px;
    font-weight: bolder;
    background-color: #fff9eb;
  }

  &__item--2 {
    border-color: #81cee7;
    color: #81cee7;
  }

  &__item--2 &__index {
    border-color: #81cee7;
    background-color: #edfaff;
  }

  &__item--3 {
    margin-top: 80px;
    border-color: #925ec3;
    color: #925ec3;
  }

  &__item--3 &__index {
    border-color: #925ec3;
    background-color: #f7edfe;
  }

  &__item--4 {
    margin-top: 80px;
    border-color: #2ec3a3;
    color: #2ec3a3;
  }

  &__item--4 &__index {
    border-color: #2ec3a3;
    background-color: #edf9f6;
  }

  &__content {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    height: 64px;
    overflow: hidden;
    padding: 10px;
    font-size: 15px;
    font-weight: bolder;
    line-height: 1.4;
  }

  &__item-arrow {
    display: block;
    position: absolute;
    width: 48px;
    height: 2px;
    background-color: #a5b2e9;

    &::after {
      content: "";
      display: block;
      position: absolute;
      top: -10px;
      right: -22px;
      border: 11px solid;
      border-color: transparent transparent transparent #4768fa;
    }

    &--1 {
      top: 86px;
      left: 50%;
      margin-left: -30px;
    }

    &--2 {
      top: 156px;
      right: calc(25% - 40px);
      transform: rotate(90deg);
    }

    &--3 {
      bottom: 60px;
      left: 50%;
      margin-left: -18px;
      transform: rotate(180deg);
    }
  }
}

.firewarn-right{
  float: left;
  width: 518px;
  height: 100%;
  margin-left: 20px;
  margin-top: 20px;
}

.firewarn-kzq{
  box-sizing: border-box;
  width: 533px;
  padding: 3%;
  border: solid 1px #2667ae;
  background-color: #0a365c;
}

.firewarn-xfss{
  box-sizing: border-box;
  width: 533px;
  height: 731px;
  overflow: auto;
  padding: 15px 7px 12px 20px;
  margin: 18px 0 0 0;
  border: solid 1px #2667ae;
  background-color: #0a365c;
}

.firewarn_xfsb{
  display: block;
  width: 100%;
  margin: 13px 0 0 0;
  background-color: #193c5d;

  &_msg {
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
  }

  &_msg-icon_zc::before {
    content: "";
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #1a9eff;
  }

  &_msg-icon_yc::before {
    content: "";
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #f9717f;
  }

  &_msg-name {
    font-family: MicrosoftYaHei;
    font-size: 20px;
    line-height: 30px;
    color: #fff;
  }

  &_msg-wz {
    font-family: MicrosoftYaHei;
    font-size: 20px;
    line-height: 30px;
    color: #4ee0dc;
  }
}

.hzxxTJ-position{
  position: relative;
  top: -206px;
  left: 195px;
}

.kzq-background1{
  background-image: url('/monitor/images/hj/控制器.svg');
}

.kzq-background{
  top: 50px;
  width: 518px;
  height: 255px;
  position: relative;

  .kzq-item{
    position: relative;
    background-image: url('/monitor/images/hj/控制器_名字框.svg');
    background-repeat: no-repeat;
    height:  24px;

    .kzq-text{
      font-family: AlibabaPuHuiTi_2_55_Regular;
      font-weight: 400;
      font-size: 12px;
      color: #FFFFFF;
      line-height: 12px;
      margin-left: 5px;
    }

  }

  .icon_pyfj {
    position: absolute;
    top: -40px;
    left: 50px;
  }
  .icon_sfj {
    position: absolute;
    top: -40px;
    left: 350px;
  }
  .icon_xfdt {
    position: absolute;
    top: 130px;
    left: 50px;
  }
  .icon_sb {
    position: absolute;
    top: 130px;
    left: 350px;
  }
  .icon_kzq {
    position: absolute;
    top: 20px;
    left: 200px;
  }
}
.hjlb-tj{
  position: absolute;
  font-family: AlibabaPuHuiTi_2_85_Bold;
  font-weight: 700;
  font-size: 18px;
  color: #FF5E1D;
  letter-spacing: 0;
  top: -34px;
  left: 110px;
}

</style>
