<!--
 * @Author: DQL
 * @Date: 2022-11-30 10:13:40
 * @LastEditors: PXH
 * @LastEditTime: 2023-12-25 20:03:06
 * @Description: file content
-->
<template>
  <div

    class="c-sinfo"
  >
    <div class="c-sinfo__header">
      <span class="c-sinfo__header_title">故障设备报修记录</span>
    </div>
    <div
      v-show="filterList.length > 8"
      class="div-gd-kg"
      @click="handlGbgd()"
    >
      {{ gdIshowWz }}
    </div>
    <div
      class="div-gd-kg_radio"
      @click="handlCkqb()"
    >
      <template>
        <el-radio
          v-model="radio"
          label="1"
        >
          本月
        </el-radio>
        <el-radio
          v-model="radio"
          label="2"
        >
          全部
        </el-radio>
      </template>

      <div class="div-body_top">
        <div
          class="div-body_top_zt"
          :class="{'div-wxzt_onclick': wxzt === '2'}"
          @click="handlZtClick('2')"
        >
          已维修 {{ ywxNum }}
        </div>
        <div
          class="div-body_top_zt div-body_top_zt_dwx"
          :class="{'div-wxzt_onclick': wxzt === '0'}"
          @click="handlZtClick('0')"
        >
          待维修 {{ dwxNum }}
        </div>
      </div>
    </div>
    <div
      id="div-jrfxsssj"
      class="div-body"
    >
      <div class="div-body-content">
        <div
          v-for="(item,index) in filterList"
          :key="index"

          class="div-body_item"
        >
          <div class="div-item_left">
            <div
              class="div-item_img"
            >
            <!-- <img src="/monitor/images/top/t_zshj.png"> -->
            </div>
            <div
              :class="{

                'div-item_wxzt_dwx' : item.wxzt === '0',
                'div-item_wxzt_ywx' : item.wxzt === '2',

              }"
            >
              {{ item.wxzt==='2'?'已维修':'待维修' }}
            </div>
          </div>
          <div style="margin-left: 20px;  width: 60px">
            {{ item.ssdwmc }}
          </div>

          <div class="div-azwz">
            <div>{{ item.azwz }}</div>
          </div>
          <div
            style="width:140px;"
            class="div-azwz"
          >
            <div>{{ item.gzqkmx }}</div>
          </div>

          <div style="margin: 10px;">
            {{ item.gzfxsj }}
          </div>

          <div
            class="div-right"
            style="margin-right: 10px"
          >
            <div style="margin: 5px;">
              {{ item.wxry?'（ 维修：':'' }} {{ item.wxry }} {{ item.wxsj }}
            </div>
            <div
              style="width:145px;"
              class="div-azwz"
            >
              <div>
                {{ item.wxff }}
              </div>
            </div>
            {{ item.wxry?'）':'' }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { monitorService } from '../monitor.service';
import EventBus from '@/event-bus';
export default {
  name: 'Jrfxsssj',
  props: {
    code: {
      type: String,
      default: function () {
        return '';
      }
    }
  },
  data () {
    return {
      loading: false,
      gdIshow: true,
      gdIshowWz: '关闭滚动',
      bgStr: 'div-xxlx-bg_',
      dataList: [
      ],
      clock: null,

      radio: '2',
      ywxNum: 0,
      dwxNum: 0,
      wxzt: ''
    };
  },
    computed: {
    filterList () {
      var dataList = this.dataList;
      if (this.wxzt) {
       dataList = dataList.filter(item => {
            return item.wxzt === this.wxzt;
        });
      }
      console.log('filterList', dataList);

      return dataList;
    }
  },
  methods: {
    handlCkqb () {
        this.xxlx = '';
        this.xxqr = '';
    },

    handlZtClick (val) {
      if (val === this.wxzt) {
        this.wxzt = '';
      } else {
        this.wxzt = val;
      }
    },
    /**
     * 获取故障设备维修情况
     */
    querySbWxqk () {
      const _this = this;
      monitorService.querySbWxqk(_this.radio)
        .then(res => {
          _this.dataList = res.data;
          _this.ywxNum = _this.dataList.filter(item => item.wxzt === '2').length;
          _this.dwxNum = _this.dataList.filter(item => item.wxzt === '0').length;
          console.log('获取故障设备维修情况', res.data);
        })
        .catch(error => {
          console.log('获取故障设备维修信息失败！', error);
        })
        .finally(() => {

        });
    },
    handlGbgd () {
      this.gdIshow = !this.gdIshow;
      this.gdIshowWz = this.gdIshow ? '关闭滚动' : '开启滚动';
    }

  },
  watch: {
    radio (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.querySbWxqk();
      }
    }

  },
  beforeDestroy () {
    // this.autoScroll(true);
  },

  mounted () {
    const _this = this;
    // _this.autoScroll();
    _this.querySbWxqk();
    this.clock = setInterval(function () {
      if (_this.code) {
            _this.querySbWxqk();
        } else {
          clearInterval(_this.clock);
      }
    }, 10 * 1000);

    // // 拿到表格挂载后的真实DOM
    const divData = document.getElementById('div-jrfxsssj');
    setInterval(() => {
      if (!_this.gdIshow) return;
      // 元素自增距离顶部1像素
      divData.scrollTop += 1;

      // 判断元素是否滚动到底部(可视高度+距离顶部=整个高度)
      if (divData.clientHeight + divData.scrollTop === divData.scrollHeight) {
        // 重置table距离顶部距离
        divData.scrollTop = 0;
      }
    }, 50);

    EventBus.$on('topBjzt', function (data) {
      console.log('topBjzt', data);
      _this.xxlx = data.xxlx;
      _this.xxqr = data.xxqr;
    });

    // const
  },
    destroyed () {
    clearInterval(this.clock);
     // 注意移除监听！
    //  window.removeEventListener('message', this.handleMessage);
  }
};
</script>

<style lang="less" scoped>

.c-sinfo{
  box-shadow: inset 0 0 18px 2px #0093ff66;
  // background: url('/images/screen/bottom-box-bg.svg') no-repeat center top;
  // background-size: cover;
  background-image: linear-gradient(180deg, #001235f0 0%, #011733f0 100%);

  &__header{
    height: 30px;
    background: url('/monitor/images/tj/bottom.png');

    &_title{
      margin-left: 25px;
      font-family: HYYaKuHei-85J;
      font-size: 16px;
      font-weight: 85J;
      line-height: 24px;
      color: #FFF;

    }
  }

  &__body{
    display: flex;
    width: 100%;
    padding: 20px 24px 0 20px;
  }
}

.div-gd-kg{
  width: 56px;
  padding: 4px;
  border-radius: 2px;
  background-color: #3263d1;
  color: #fff;
  position: absolute;
  right: 20px;
  top: 20px;
}
.div-gd-kg_radio{

  padding: 4px;
  border-radius: 2px;

  color: #fff;
  position: absolute;
  left: 210px;
  top: 20px;
  display: flex;
}
.div-body{
  // font: 20px;
   width: 95%;
  height: 250px;
  overflow-y: auto;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  position: relative;
  margin: auto;
  margin-top: 15px;

  &_item{
    display: flex;
    width: 100%;
    height: 36px;
    background-color: rgb(3, 37, 77);
    align-items: center;
    position: relative;
    margin: 2px;
    border-radius: 3px;
    // animation: scroll 5s linear infinite;
  }

}
/* 鼠标悬停停止滚动 */
// .div-body_item:hover{
//   animation-play-state: paused;
// }
@keyframes scroll {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
}

.div-body::-webkit-scrollbar:vertical {
  width: 6px;
}

.div-body::-webkit-scrollbar-thumb {
  background: #09369A;
}
.div-body::-webkit-scrollbar-track {

  background: #08193F;
}
.div-body-content {
  // flex-grow: 1;
  // display: flex;
  // flex-direction: column;
  // justify-content: flex-end;
  // background-color: #011233;

}
.div-item_left{
  display: flex;
  align-items: center;

  height: 27px;
  padding: auto;

  }

.div-item_img{
  width: 20px;
  height: 20px;
  //  background: url('/monitor/images/top/t_zshj.png') no-repeat center right;
  // background-size: 100% 100%;
  margin: 0 5px;

  &_zs{
    // background: url('/monitor/images/top/t_zshj.png') no-repeat center right;
    background-size: 100% 100%;
  }
  &_gz{
    // background: url('/monitor/images/top/t_gz.svg') no-repeat center right;
    background-size: 100% 100%;
  }
  &_wb{
    // background: url('/monitor/images/top/t_wb2.png') no-repeat center right;
    background-size: 100% 100%;
  }
  &_dqr{
    // background: url('/monitor/images/top/t_dqr.png') no-repeat center right;
    background-size: 100% 100%;
  }
}
.div-item_wxzt{
  padding: 2px;
  font: 20px;

  &_ywx{
   color: #00F8E2;
  }
  &_dwx{
   color: #e7e409
  }

}
.div-item_num{

  display: flex;
  width: 60%;
  margin-left: 200px;
  &_test{
    width: 25%;
    margin: auto;
    font-size: 22px;
    color: #fff;
  }
}
.div-xxlx-item{
  padding: 2px;
  font: 20px;
  &_hj{
  color: #e1574d;
  }
  &_gz{
  color: #a5b3cb;
  }
}
.div-right{
  display: flex;
  align-items: center;
  position: absolute;
  right: 0px;
}
.dev-jz-img{
  width: 14px;
  height: 12.9px;
  margin: 5px;
}
.div-azwz{
  display: flex;
  align-items: center;
  width: 200px;
  margin: 0 10px;
  line-height: 14px;
}

.div-body_top{
    display: flex;
    margin-top: -3px;
    &_zt{
      width: 64px;
      height: 20px;
      background: #00d4f81a;
      box-shadow: inset 0 0 3px 0 #00d4f880;
      border-radius: 4px;
      text-align: center;
      line-height: 20px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      color: #00F8E2;
      font-size: 12px;
      margin-left: 20px;
      &_dwx{
        background: #e7e4091a;
        box-shadow: inset 0 0 3px 0 #e7e40980;
        color: #e7e409;
      }
    }
  }
  .div-wxzt_onclick{
    font-weight: 700;
    color: #00D1FF;
    background-color: rgb(0, 47, 114);
    box-shadow: inset 0px 0px 6px 0px #006ce8;
    border: 1px solid #006ce8;

  }

</style>
