<template>
  <div class="l-screen__chart">
    <div class="monitor_schart monitor_srobot-wrap">
      <div class="monitor_schart-header u-font-PFSC">
        <sub-title inner-text="机器人监控室" />
      </div>
      <div class="monitor_schart-body">
        <div
          v-for="roomItem in dwList"
          :key="`roomItem_${roomItem.code}`"
          class="monitor_srobot"
          :class="{'monitor_srobot--active': activeMonitoringRoom===roomItem.code}"
        >
          <i
            class="monitor_srobot-icon"
            @click="changeDw(roomItem.code)"
          />
          <div class="monitor_srobot-inner">
            <div class="monitor_srobot-title u-font-PFSC">
              {{ roomItem.name }}
            </div>
            <div class="monitor_srobot-body">
              <!-- <div class="monitor_srobot-label">
                累计运行时长
              </div> -->
              <div class="monitor_srobot-num">
                {{ roomItem.yxsc || 0 }}
                <span class="monitor_srobot-extra">小时</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="c-schart c-stable-wrap">
      <div class="c-schart__header u-font-PFSC">
        <sub-title inner-text="消防设备" />
      </div>
      <div class="c-schart__body">
        <div style="width: 100%;max-height: 276px;overflow: auto;">
          <table class="monitor_table">
            <thead>
              <tr class="monitor_table__head">
                <th width="150px">
                  系统名称
                </th>
                <th>总数</th>
                <th>正常</th>
                <th>异常</th>
                <th>离线</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in xtsbPageList"
                :key="item.id"
                class="monitor_table__body"
              >
                <td> {{ item.xtMc }}</td>
                <td> {{ item.zs }}</td>
                <td> {{ item.zc }}</td>
                <td class="u-text-danger">
                  {{ item.yc }}
                </td>
                <td> {{ item.lx }}</td>
              </tr>
              <tr
                v-for="item in 7-(xtsbPageList?xtsbPageList.length:0)"
                :key="item"
                class="monitor_table__body"
              >
                <td> &nbsp; </td>
                <td> &nbsp; </td>
                <td> &nbsp; </td>
                <td> &nbsp; </td>
                <td> &nbsp; </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="c-schart c-sli-wrap">
      <div class="c-schart__header u-font-PFSC">
        <sub-title inner-text="消防维保情况" />
      </div>
      <div class="c-schart__body">
        <ul class="c-sli">
          <li class="c-sli__item">
            <span class="c-sli__label">维保单位：</span>
            <span class="c-sli__num">{{ xfqbqkInfo.wbdw }}</span>
          </li>
          <li class="c-sli__item">
            <span class="c-sli__label">维保时间：</span>
            <span class="c-sli__num">{{ xfqbqkInfo.byrq | date("yyyy-MM-dd") }}</span>
          </li>
          <li class="c-sli__item">
            <span class="c-sli__label">维护人员</span>
            <span class="c-sli__num">{{ xfqbqkInfo.whry }}</span>
          </li>
          <!-- <li class="c-sli__item">
            <span class="c-sli__label">维保内容：</span>
            <span class="c-sli__num">对自动喷水灭火系统/火灾警报系统进行定期维护保养</span>
          </li> -->
          <li class="c-sli__item">
            <span class="c-sli__label">检查日期：</span>
            <span class="c-sli__num">{{ xfqbqkInfo.jcsj | date("yyyy-MM-dd") }}</span>
          </li>
          <li class="c-sli__item">
            <span class="c-sli__label">检查人</span>
            <span class="c-sli__num">{{ xfqbqkInfo.jcr }}</span>
          </li>
          <!-- <li class="c-sli__item">
            <span class="c-sli__label">检查类别：</span>
            <span class="c-sli__num">巡查</span>
          </li>
          <li class="c-sli__item">
            <span class="c-sli__label">检查结果：</span>
            <span class="c-sli__num">正常</span>
          </li> -->
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import SubTitle from '../components/SubTitle.vue';
import { monitorService } from '../monitor.service';
export default {
  name: 'ChartBox',
  components: {
    SubTitle
  },
  props: {
    dwList: {
      type: Array,
      default: () => {
        return [
          // {
          //   code: 1,
          //   name: '鸿蒙智慧消防实验室',
          //   yxsc: '926'
          // }
        ];
      }
    },
    activeMonitoringRoom: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      // 消防维保情况信息
      xfqbqkInfo: {},
      // 系统设备数据
      xtsbList: [
        // { xtMc: '火灾自动报警', zs: 320, zc: 308, yc: 4, lx: 8 },
        // { xtMc: '消防水系统', zs: 85, zc: 76, yc: 4, lx: 5 },
        // { xtMc: '电气火灾系统', zs: 77, zc: 71, yc: 0, lx: 6 },
        // { xtMc: '消火栓系统', zs: 92, zc: 90, yc: 0, lx: 2 },
        // { xtMc: '防火门及卷帘系统', zs: 392, zc: 390, yc: 0, lx: 2 },
        // { xtMc: '防烟排烟系统', zs: 192, zc: 190, yc: 0, lx: 2 },
        // { xtMc: '应急照明和疏散指示系统', zs: 1092, zc: 968, yc: 23, lx: 101 }

      ],
      xtsbPage: 1,
      xtsbPageList: null
    };
  },
  methods: {
    changeDw (dwId) {
      this.$parent.changeDw(dwId);
    },
    queryXfsb () {
      this.xtsbList = [];
      monitorService.queryXfsb(this.activeMonitoringRoom)
        .then(rsp => {
          this.xtsbList = rsp.data;
          this.xtsbPage = 1;
          this.switchXfsb();
          if (!this.clock) {
            this.clock = setInterval(this.switchXfsb, 5000);
          }
        });
    },
    switchXfsb () {
      const pageSize = 7; // 分页大小
      const pageTotal = Math.ceil(this.xtsbList.length / pageSize);
      this.xtsbPageList = this.xtsbList.slice((this.xtsbPage - 1) * pageSize, this.xtsbPage * pageSize);
      this.xtsbPage = this.xtsbPage + 1 > pageTotal ? 1 : this.xtsbPage + 1;
    },
    queryXfwbqk () {
      monitorService.queryXfwbqk(this.activeMonitoringRoom)
        .then(rsp => {
          this.xfqbqkInfo = rsp.data;
        });
    }
  },
  watch: {
    activeMonitoringRoom () {
      if (this.activeMonitoringRoom) {
        this.queryXfsb();
        this.queryXfwbqk();
      }
    }
  },
  destroyed () {
    clearInterval(this.clock);
  }
};
</script>

<style scoped lang="scss">
@import '../../virtual/chart/chart.scss';
@import '../monitor.scss';
</style>
