<template>
  <div>
    <div :class="dw?'monitor_centerBox-map2':'monitor_centerBox-map'" />
    <div
      v-if="!dw"
      class="monitor_center_box-boder"
      :class="introduction.lwzt.style"
    >
      <div
        class="monitor_center_box "
      >
        <div class="monitor_center_box-title">
          当前联网单位状态
        </div>
        <div class="monitor_center_box-body">
          <div class="monitor_center_box-body-li">
            <div class="monitor_center_box-body-li-half">
              <div class="monitor_center_box-body-li-img">
                <img src="/monitor/images/centerbox_icon1.png">
              </div>
              <div class="monitor_center_box-body-li-title">
                在线
              </div>
              <div class="monitor_center_box-body-li-text">
                {{ dwList&&dwList.length>0?dwList.filter(item => item.lwzt === '1').reduce((rs, item) => { return rs + 1; }, 0):0 }}
              </div>
            </div>
            <div class="monitor_center_box-body-li-half">
              <div class="monitor_center_box-body-li-img">
                <img src="/monitor/images/centerbox_icon2.png">
              </div>
              <div class="monitor_center_box-body-li-title">
                离线
              </div>
              <div class="monitor_center_box-body-li-text">
                {{ dwList&&dwList.length>0?dwList.filter(item => item.lwzt != '1').reduce((rs, item) => { return rs + 1; }, 0):0 }}
              </div>
            </div>
          </div>

          <div
            v-for="item in dwList"
            :key="item.id"
            class="monitor_center_box-body-li"
          >
            <div class="monitor_center_box-body-li-img">
              <img src="/monitor/images/centerbox_icon3.png">
            </div>
            <div class="monitor_center_box-body-li-title">
              {{ item.name }}
            </div>
            <div class="monitor_center_box-body-li-text">
              {{ item.lwzt =='1'?'在线':'离线' }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="dw"
      class="monitor_center_box2"
      @click="changeDw(null)"
    >
      <div class="richWrapper">
        <div class="richTitle">
          {{ dw.name }}
        </div>
        <div class="richContent">
          <ul>
            <li>
              <div class="richContent-left">
                状态：
              </div><div class="richContent-right">
                {{ dw.lwzt =='1'?'在线':'离线' }}
              </div>
            </li>
            <li>
              <div class="richContent-left">
                值班人员：
              </div><div class="richContent-right">
                {{ dw.zbry }}
              </div>
            </li>
            <li>
              <div class="richContent-left">
                消防责任人：
              </div><div class="richContent-right">
                {{ dw.xfaqzrrmc }}
              </div>
            </li>
            <li>
              <div class="richContent-left">
                地址：
              </div><div class="richContent-right">
                {{ dw.dz }}
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import EventBus from '../../../event-bus';

export default {
  name: 'CenterBox',
  props: {
    dwList: {
      type: Array,
      default: () => {
        return [
          // {
          //   id: 1,
          //   name: '鸿蒙智慧消防实验室',
          //   dz: '广州市海珠区新港东路1066号一层A7、A8商铺自编1-E01',
          //   lwzt: '0',
          //   zbry: '刘波',
          //   xfaqzrrmc: '江小白',
          //   lng: 113.375879, // 地理经度
          //   lat: 23.103363// 地理纬度
          // }
        ];
      }
    }
  },
  data () {
    return {
      dw: null,
      introduction: {
        lwzt: {
          style: []
        }
      }

    };
  },
  methods: {
    changeDw (dw) {
      this.dw = dw;
      if (!dw) this.$parent.changeDetail(false);
    }

  },
  mounted () {
    const _this = this;
    EventBus.$on('introduce-hightLight-lwzt', () => {
      _this.introduction.lwzt.style.splice(0);
      _this.introduction.lwzt.style.push('iff-hightLight');
    });
    EventBus.$on('introduce-hide-lwzt', () => {
      _this.introduction.lwzt.style.splice(0);
      _this.introduction.lwzt.style.push('iff-hide');
    });
    EventBus.$on('introduce-clear-lwzt', () => {
      _this.introduction.lwzt.style.splice(0);
    });
    EventBus.$on('introduce-goto-sy', () => {
      _this.changeDw(null);
    });
  }
};
</script>

<style scoped lang="scss">

@import '../../introuduce/introduce.css';
.monitor_centerBox-map {
  width: 100%;
  height: 100%;
  background: url('/monitor/images/map.jpg') no-repeat black;
  background-position: 0 0;
  // background-size: 122%;
  background-size: cover;
  filter: blur(1px);
}

.monitor_centerBox-map2 {
  width: 100%;
  height: 100%;
  background: url('/monitor/images/map2.png') no-repeat black;
  background-size: cover;
}

.monitor_center_box2 {
  position: absolute;
  top: 300px;
  left: 1000px;
  cursor: pointer;
}

.monitor_center_box {
  box-sizing: border-box;
  width: 316px;
  height: 310px;
  padding: 18px 12px 0 12px;
  border: solid 1px #57bfff;
  box-shadow: inset 0 0 40px 0px
    rgba(19, 122, 225, 0.3);
  background-color: #030f22;

  &-boder {
    position: absolute;
    top: 95px;
    left: 450px;
    box-sizing: border-box;
    width: 327px;
    height: 320px;
    padding: 3px;
    border: dashed 2px #3699ff;
    background-color: #123f5e;
  }

  &-title {
    width: 188px;
    height: 35px;
    margin: 0 auto;
    border: dashed 1px #3699ff;
    font-family: MicrosoftYaHei;
    font-size: 16px;
    line-height: 35px;
    color: #fffefe;
    text-align: center;
    background: url('/monitor/images/centerbox_title.png') no-repeat center center;
    background-size: cover;

  }

  &-body-li {
    display: flex;
    align-items: center;
    width: 291px;
    height: 40px;
    margin: 0 0 18px 0;
    border: solid 1px #29adff;
    box-shadow: inset 0 0 25px 0px
      rgba(0, 174, 255, 0.4);

    &-half{
      display: flex;
      align-items: center;
      width: 140px;
    }

    &-img {
      border: dashed 1px #3699ff;
    }

    &-title{
      flex-shrink: 0;
      font-family: MicrosoftYaHei;
      font-size: 14px;
      line-height: 40px;
      color: #e8eaee;
    }

    &-text {
      margin-left: auto;
      font-family: MicrosoftYaHei;
      font-size: 14px;
      line-height: 40px;
      color: #10b6ff;
    }

    &-half &-text{
      margin-left: 20px;
    }
  }

  &-body-li:first-child {
    margin-top: 20px;
  }
}
</style>
