<template>
  <div class="monitor_fire">
    <div class="monitor_fire-body">
      <div class="hj">
        <div class="monitor_fire-left">
          <img src="/monitor/images/top/t_zshj.png">
        </div>
        <div class="monitor_fire-right">
          <div
            class="monitor_fire-right-title"
            style="color: #E1574D;;font-size: 16px;"
          >
            真实火警
          </div>
          <div
            class="monitor_fire-right-warn"
            style="color: #E1574D;;font-size: 24px;"
          >
            {{ tjxxInfo.zshj === null?'0': tjxxInfo.zshj }}
          </div>
        </div>
      </div>
    </div>
    <div
      class="monitor_fire-body"
    >
      <div class="hj">
        <div class="monitor_fire-left">
          <img src="/monitor/images/top/t_hj.png">
        </div>
        <div class="monitor_fire-right">
          <div
            class="monitor_fire-right-title"
            style="color: #E1564D;font-size: 16px;"
          >
            火警
          </div>
          <div
            class="monitor_fire-right-warn"
            style="color: #E1564D;font-size: 24px;"
          >
            {{ tjxxInfo.jthj === null?'0': tjxxInfo.jthj }}
          </div>
        </div>
      </div>
    </div>
    <div class="monitor_fire-body">
      <div class="hj">
        <div class="monitor_fire-left">
          <img src="/monitor/images/top/t_dqr.png">
        </div>
        <div class="monitor_fire-right">
          <div
            class="monitor_fire-right-title"
            style="color: #F1DD55;font-size: 16px;"
          >
            待确认
          </div>
          <div
            class="monitor_fire-right-num"
            style="color: #F1DD55;font-size: 24px;"
          >
            {{ tjxxInfo.dqr === null?'0': tjxxInfo.dqr }}
          </div>
        </div>
      </div>
    </div>
    <div class="monitor_fire-body">
      <div class="hj">
        <div class="monitor_fire-left">
          <img src="/monitor/images/top/t_wb2.png">
        </div>
        <div class="monitor_fire-right">
          <div
            class="monitor_fire-right-title"
            style="color: #BDCAE1;font-size: 16px;"
          >
            误报
          </div>
          <div
            class="monitor_fire-right-num"
            style="color: #BDCAE1;font-size: 24px;"
          >
            {{ tjxxInfo.wb === null?'0': tjxxInfo.wb }}
          </div>
        </div>
      </div>
    </div>
    <div class="monitor_fire-body">
      <div class="hj">
        <div class="monitor_fire-left">
          <img src="/monitor/images/top/t_wbcs2.png">
        </div>
        <div class="monitor_fire-right">
          <div
            class="monitor_fire-right-title"
            style="color: #BDCAE1;font-size: 16px;"
          >
            维保数量
          </div>
          <div
            class="monitor_fire-right-num"
            style="color: #BDCAE1;font-size: 24px;"
          >
            {{ tjxxInfo.wbcs? tjxxInfo.wbcs: '0' }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { monitorService } from '../monitor.service';

export default {
  name: 'DetailTopBoxT',
  props: {
    // 联网单位
    code: {
      type: String,
      default: 'null'
    }
  },
  data () {
    return {
      clock: null,
      // 火警信息
      tjxxInfo: {
        wbcs: '0',
        wb: '0',
        dqr: '0',
        zshj: '0',
        jthj: '0'
      }
    };
  },
  methods: {
    queryTjxxs () {
      monitorService.queryTjxxs(this.code)
        .then(rsp => {
          this.tjxxInfo = rsp.data;
        });
    }
  },
  watch: {
    code (newVal, oldVal) {
      const _this = this;
        this.queryTjxxs();
        this.clock = setInterval(function () {
          _this.queryTjxxs();
        }, 10 * 1000);
    }
  },
  created () {
    const _this = this;
    this.queryTjxxs();
    this.clock = setInterval(function () {
      _this.queryTjxxs();
    }, 10 * 1000);
  },
  destroyed () {
    clearInterval(this.clock);
  }
};
</script>

  <style scoped lang="scss">
  .monitor_fire {
    height: 120px;
    padding: 23px 0 13px 57px;
    // background: url('/monitor/images/fireTitle.png');
    // background-size: cover;
  }

  .monitor_fire-body{
    display: flex;
    float: left;
    width: 20%;
    // padding: 0 0 0 0;
  }
  .hj{
    display: flex;
    float: left;
    background-repeat:no-repeat;
    z-index:-1;
    width: 180px;
    height: 70px;
    background-image: url('/monitor/images/top/t_bj.png');
  }
  .monitor_fire-left{
    // margin: auto;
    padding:14px;

  }

  .monitor_fire-right {
    margin: 12px 0 0 10px;

    &-title{
      font-family: MicrosoftYaHei;
      font-size: 14px;
      font-stretch: normal;
      font-weight: normal;
    //   color: #d6dee7;
    }

    &-num {
      margin: 8px 0 0 0;
      font-family: Digital-7Mono;
      font-size: 20px;
      font-stretch: normal;
      font-weight: normal;
      letter-spacing: 0;
      color: #278dff;
    }

    &-warn {
      margin: 8px 0 0 0;
      font-family: Digital-7Mono;
      font-size: 20px;
      font-stretch: normal;
      font-weight: normal;
      letter-spacing: 0;
      color: #e4584e;
    }
  }

  </style>
