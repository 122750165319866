<template>
  <div style=" width: 100%;height: 100%;">
    <div class="nav_warp">
      <el-menu
        :default-active="activeMenu"
        class="el-menu-vertical-demo"
        :collapse="collapse"
        @select="handleSelect"
      >
        <el-menu-item index="home">
          <i class="el-icon-menu" />
          <span slot="title">默认视角</span>
        </el-menu-item>
        <!-- <el-menu-item index="2">
          <i class="el-icon-refresh" />
          <span slot="title">自动旋转</span>
        </el-menu-item> -->
      </el-menu>
    </div>
    <a-scene
      embedded
      vr-mode-ui="enabled: false"
      inspector="url: /3DModel/js/aframe-inspector.min.js;"
      renderer="physicallyCorrectLights: true;colorManagement: true; logarithmicDepthBuffer:true; "
      style=" width: 100%;height: 100%;"
    >
      <a-assets>
        <a-asset-item
          id="font-STSONG"
          src="/3DModel/font/STSONG.TTF"
        />
        <a-asset-item
          id="STXingkaiFont"
          src="/3DModel/font/STXingkai.json"
        />
        <a-asset-item
          :id="`${mapRoot.adcode}`"
          :src="'/3DModel/geo/' + mapRoot.adcode + mapkeyLast"
        />
        <a-asset-item
          v-for="map in maps"
          :id="map.adcode"
          :key="map.adcode"
          :src="'/3DModel/geo/' + map.adcode + mapkeyLast"
        />
        <a-asset-item
          id="office"
          src="/3DModel/map/office.gltf"
        />
        <a-mixin
          id="officeMixin"
          gltf-model="#office"
          scale="0.001 0.001 0.001"
          rotation="90 0 0"
        />
      </a-assets>

      <a-camera
        id="camera"
        fov="60"
        near="0.001"
        far="10000"
        position="0 0 0"
        look-controls="enabled: false; pointerLockEnabled: false;"
        wasd-controls="enabled: false; "
        orbit-controls="enablePan: false;
          target: 14.814795017242432 -9.117825269699097 0; initialPosition: 14.814795017242432 -16.013853993726336 9.752457348033753; position: 14.814795017242432 -16.013853993726336 9.752457348033753;
          minDistance: 0.6; maxDistance: 100;
          minPolarAngle: 0; maxPolarAngle: 180;
          minAzimuthAngle: -90; maxAzimuthAngle: 90;
          rotateSpeed: 0.5; zoomSpeed: 1;"
      />

      <!-- 环境光
      <a-light
        id="ambientLight"
        type="ambient"
        color="#BBB"
        intensity="1"
      />-->
      <!-- 平行光
      <a-light
        id="dircetLight"
        type="directional"
        color="#FFF"
        intensity="1"
        position="-0.5 1 1"
      />-->

      <a-sky color="#020b27" />
      <a-entity
        id="star"
        star
      />

      <a-entity
        rotation="0 0 0 "
        scale="1 1 1"
      >
        <!-- 中国地图 -->
        <a-entity
          :id="`${mapkeyPre}${mapRoot.adcode}`"
          class="geoMap"
          position="0 0 -3.5"
          :visible="mapRoot.visible==true"
          :material="
            `color: #409EFF;
            opacity: 1;`"
          :geojson-loader="`src: #${mapRoot.adcode};`"
          geo-projection="
            projection: geoEquirectangular;
            height: 100;
            width: 100;"
          geo-extrude-renderer="
            splitMesh: true;
            bevelEnabled: false;
            depth: 3;
            sphereRadius:1"
          :geo-text-renderer="
            `font: #font-STSONG;
            fontSize: 1.3;
            opacity: ${mapRoot.opacity};
            positionZ: 3.01;`"
          geo-outline-renderer="
            color:#d9ecff;
            position:0 0 3.01"
          :geo-cursor="`
            enabled: true;
            color:#909399;`"
        />
        <!-- 省市地图 -->
        <a-entity
          v-for="map in maps"
          :id="mapkeyPre + map.adcode"
          :key="map.adcode"
          class="geoMap"
          :position="`0 0 ${map.level == 'city' ? 0 : -0.5}`"
          :visible="`${map.visible}`"
          :material="
            `color: #409EFF;
            opacity: 1;`"
          :geojson-loader="'src: #' + map.adcode"
          geo-projection="
            projection: geoEquirectangular;
            height: 100;
            width: 100;"
          :geo-extrude-renderer="
            `parentGeoProjection: #${mapkeyPre}${mapRoot.adcode};
            splitMesh: true;
            bevelEnabled: false;
            depth: 0.5;
            sphereRadius:1`"
          :geo-text-renderer="
            `parentGeoProjection: #${mapkeyPre}${mapRoot.adcode};
            font: #font-STSONG;
            fontSize: ${map.level == 'city' || map.adcode === 500000 ? 0.05 : 0.3};
            opacity: ${map.opacity};
            positionZ: 0.501;`"
          :geo-outline-renderer="
            `parentGeoProjection: #${mapkeyPre}${mapRoot.adcode};
            color:#d9ecff;
            position:0 0 0.501`"
          :geo-cursor="`
            enabled: true;
            color:#909399;`"
        />
        <!-- 联网单位标注 -->
        <a-entity
          id="marker-map"
          position="0 0 0"
          material="
            color: #daf887;
            opacity: 1"
          :geo-marker-renderer="
            `geoJson: ${JSON.stringify(dwGeoJson)};
            parentGeoProjection: #${mapkeyPre}${mapRoot.adcode};
            point: false;
            pointRadius: ${mapSelect && mapSelect.level === 'city' ? 0.05 : 0.1};
            line: false;
            lineheight: ${mapSelect && mapSelect.level === 'city' ? 1 : 1};
            linecolor: #ffff95;
            position:0 0 0.72;
            buildingMixin: officeMixin;
            buildingFont: #font-STSONG;
            buildingFontSize: 10;
            buildingOffset: 0 0 0.51`"
        />

        <!-- 云端 -->
        <a-entity
          id="cloud"
          geometry="primitive: circle; radius: 1"
          material="side: double; color: #58b3cc; emissive: #58b3cc;"
          :position="`${markerCloud.x} ${markerCloud.y} ${markerCloud.z}`"
          scale="0.1 0.1 0.1"
        >
          <a-entity
            class="cloud"
            geometry="primitive: ring; radiusInner: 1.1; radiusOuter: 1.2;"
            material="side: double; color: #58b3cc; emissive: #58b3cc;"
            animation__1="property: geometry.radiusInner;
            from: 1.1; to: 1.4;
            dur: 1000; delay: 0; loop:true;"
            animation__2="property: geometry.radiusOuter;
            from: 1.2; to: 1.5;
            dur: 1000; delay: 0; loop:true;"
          />
        </a-entity>
        <a-entity
          v-for="aMarker in markerList"
          :key="aMarker.geoData.id"
          :line="`
            start: ${aMarker.position.x} ${aMarker.position.y} 0.61;
            end: ${markerCloud.x} ${markerCloud.y} ${markerCloud.z};
            color: #e77a56;`"
        >
          <a-entity
            geometry="primitive: sphere; radius: 0.01"
            material="side: double; color: #ffff95; emissive: #ffff95;"
            :animation__1="`property: position;
            from: ${aMarker.position.x} ${aMarker.position.y} 0.61;
            to: ${markerCloud.x} ${markerCloud.y} ${markerCloud.z};
            dur: 3000; delay: 0; loop:true;`"
          />
        </a-entity>
      </a-entity>
    </a-scene>
  </div>
</template>

<script>

export default {
  name: 'ThreeMap',
  components: {

  },
  props: {
    dwList: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data () {
    return {
      dw: null,
      activeMenu: null,
      collapse: true,
      mapkeyPre: 'extrudeMap-',
      mapkeyLast: '_full.json',
      mapRoot: { adcode: 100000, visible: true, level: 'country', opacity: 0.4 },
      maps: [
        { adcode: 440000, visible: true, level: 'province', opacity: 0.4 },
        { adcode: 440100, visible: true, level: 'city', opacity: 1 },
        { adcode: 500000, visible: false, level: 'province', opacity: 1 }
      ],
      historyClick: {},
      markerList: [],
      markerCloud: { x: 14.73638575874665, y: -8.730086105935364, z: 3 }
    };
  },
  computed: {
    mapSelect () {
      return this.mapRoot.visible === true && this.mapRoot.opacity === 1
        ? this.mapRoot : this.maps.find(aMap => aMap.visible && aMap.opacity === 1);
    },
    dwGeoJson () {
      const features = [];
      const reg = new RegExp('.*实验室.*');
      const dwList = this.dwList.filter(dw => !reg.test(dw.name));// 非实验室
      const example = this.dwList.find(dw => reg.test(dw.name));// 实验室
      example && dwList.push(example);
      dwList.forEach(dw => {
        const aGeo = {
          type: 'Feature',
          id: dw.code,
          properties: {
            name: dw.name
          },
          geometry: {
            type: 'Point',
            coordinates: [
              dw.lng,
              dw.lat
            ]
          }
        };
        features.push(aGeo);
      });
      return {
        type: 'FeatureCollection',
        features: features
      };
    }
  },
  methods: {
    // 切换单位
    changeDw (dw) {
      this.fitDwView(dw);
      this.dw = dw;
      if (!dw) this.$parent.changeDetail(false);
    },
    // 摄像机自适应放大显示建筑物
    fitDwView (dw) {
      if (!dw) return;
      const vec3 = document.querySelector('#marker-map').querySelector(`#${dw.name}`).object3D.position;
      document.querySelector('#camera').setAttribute('animation__look', `property: orbit-controls.target; to:${vec3.x} ${vec3.y} ${vec3.z}; dur: 1000; easing: linear; loop:1;`);

      // 沿x轴逆时针旋转45度
      // eslint-disable-next-line no-undef
      const dwPosition = new THREE.Vector3(0, 0, vec3.z + 0.5);
      // eslint-disable-next-line no-undef
      dwPosition.applyEuler(new THREE.Euler(THREE.MathUtils.degToRad(45), 0, 0, 'XYZ'));
      document.querySelector('#camera').setAttribute('animation__move', `property: orbit-controls.position; to: ${vec3.x} ${vec3.y + dwPosition.y} ${vec3.z + dwPosition.z}; dur: 1000; easing: linear; loop:1;`);
      console.log('fitDw', dw, this.dwList, vec3);
    },
    // 鼠标点击地图
    pointerup (e) {
      const selectMesh = e.detail.mesh;
      const geoData = selectMesh.geoData;
      console.log('geoMap-pointerup', geoData, selectMesh);
      const level = geoData.level;
      if (!level || level === 'district') return;// 乡镇

      const adcode = geoData.adcode;
      // 检查是否已经点击过
      if (this.historyClick[level] === adcode) return;
      this.historyClick[level] = adcode;

      const aMap = this.maps.find(map => adcode + '' === map.adcode + '');
      if (!aMap) {
        // 不存在，新增geoJson文件 和 地图el
        this.maps.push({ adcode: adcode, visible: false, level: level, opacity: 1 });
      }

      // 父级地图，变透明
      const parentCode = geoData.parent.adcode;
      const isRootMap = parentCode + '' === this.mapRoot.adcode + '';
      const parentMap = isRootMap ? this.mapRoot : this.maps.find(map => map.adcode + '' === parentCode + '');
      parentMap.opacity = 0.4;

      // 保留显示当前地图
      setTimeout(() => {
        const selectMap = this.maps.find(map => adcode + '' === map.adcode + '');
        selectMap.visible = true;
      }, 200);

      // 移动摄像头
      !this.dw && this.fitView(selectMesh);
    },
    // 摄像机自适应放大显示地图
    fitView (mesh) {
      // eslint-disable-next-line no-undef
      const box = new THREE.Box3();
      box.expandByObject(mesh);
      // eslint-disable-next-line no-undef
      const vec3 = new THREE.Vector3().addVectors(box.min, box.max).divideScalar(2);// 计算中心点坐标

      const camera = document.querySelector('#camera').getObject3D('camera');
      const width = Math.abs(box.max.x - box.min.x);
      const height = Math.abs(box.max.y - box.min.y);
      const cameraAspect = camera.aspect; // 长宽比
      const cameraFov = camera.fov / 2; // 视野角度
      const radius = cameraFov * (Math.PI / 180);
      const cameraWidth = width / cameraAspect < height ? height * cameraAspect : width;
      const far = cameraWidth / Math.cos(radius) * Math.sin(radius) + box.max.z; // 计算距离

      // 沿x轴逆时针旋转45度
      // eslint-disable-next-line no-undef
      const position = new THREE.Vector3(0, 0, far).applyEuler(new THREE.Euler(THREE.MathUtils.degToRad(45), 0, 0, 'XYZ'));

      // document.querySelector('#camera').setAttribute('animation__move', `property: object3D.children[0].position.z; to: ${far}; dur: 1000; easing: linear; loop:1;`);
      document.querySelector('#camera').setAttribute('animation__look', `property: orbit-controls.target; to:${vec3.x} ${vec3.y} 0; dur: 1000; easing: linear; loop:1;`);
      document.querySelector('#camera').setAttribute('animation__move', `property: orbit-controls.position; to: ${vec3.x} ${vec3.y + position.y} ${far}; dur: 1000; easing: linear; loop:1;`);
    },
    // 返回默认视图
    home () {
      const el = document.querySelector(`#${this.mapkeyPre}440000`);
      const mesh = el.getObject3D('extrudeMap');
      this.fitView(mesh);
      // this.mapRoot.opacity = 1;
      this.maps.filter(map => map.adcode !== 440000 && map.adcode !== 440100)
        .forEach(map => { map.visible = false; map.opacity = 1; });
      this.changeDw(null);
      this.historyClick = {};
    },
    handleSelect (index, path) {
      this[index]();
      this.activeMenu = null;
    }
  },
  beforeMount () {
    // console.log('beforeMount');
  },
  mounted () {
    document.addEventListener('geoMap-pointerup', this.pointerup.bind(this));
    document.querySelector('#marker-map').addEventListener('marker-data-ready', e => {
      this.markerList = e.detail.markerList;
    });
  }

};
</script>

<style lang="less" scoped>
.nav_warp{
  position: absolute;
  top: 190px;
  right: 446px;
  z-index: 10;

  & .nav_icon {
    border-radius: 15px;
    font-size: 30px;
    color: #909399;
    background-color: #409EFF;
  }
}
</style>
