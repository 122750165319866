<template>
  <div class="monitor">
    <sub-title inner-text="" />
    <!-- 模型加载提示 -->
    <i
      :class="modelLoadIcon"
      style="position: fixed;right: 0;bottom: 0;z-index: 999;font-size: 1.5em;color: pink;vertical-align: middle;"
    />
    <!-- 头部 Start -->
    <div
      class="monitor-header"
    >
      <div style="position: absolute;left:0;color: white;font-size: 18px;opacity: 0.5">
        {{ apiMessage }}
      </div>
      <!-- <screen-title :inner-text="screenTitle" /> -->
      <div class="monitor-header-title">
        {{ LWDW_TITLE_NAME }}
        <!-- 只有当不是全屏模式时，才显示这个按钮 -->
        <div
          v-if="!isFullScreen"
          class="fullscreen-button-container"
        >
          <el-tooltip
            class="item"
            effect="dark"
            content="点击切换全屏"
            placement="bottom"
          >
            <el-button
              class="fullscreen-button"
              type="primary"
              icon="el-icon-full-screen"
              @click="goFullScreen"
            >
              切换全屏
            </el-button>
          </el-tooltip>
        </div>
      </div>
      <div style="display: flex; justify-content: flex-end;">
        <img
          v-show="!fireFlag && slxxId!==null"
          class="breathing-svg"
          src="/monitor/images/hj/火灾告警.svg"
          style="width: 63px; height: 73px;margin-top: 10px;position: absolute;right: 50px;user-select: none"
          @click="handleOpenHj()"
        >
      </div>
    </div>

    <!-- 头部 End -->

    <!-- 百度地图 start -->
    <b-map
      v-if="isBmap"
      ref="BMap"
      :dw-list="dwList"
      class="monitor-BMap"
      :capacity-name="this.currentUser.capacity.capacityCode"
    />
    <!-- 百度地图 End -->

    <!-- 中间背景 start -->
    <center-box
      v-if="!isBmap && !isTmap"
      ref="centerBox"
      :dw-list="dwList"
      class="monitor-BMap"
    />
    <!-- 中间背景 End -->
    <!-- 3维地图 start -->
    <t-map
      v-if="isTmap"
      ref="TMap"
      :dw-list="dwList"
      class="monitor-BMap"
    />
    <!-- 3维地图 End -->

    <!-- 语音对话 start -->
    <npl
      ref="Npl"
      class="monitor_npl-wrap o-zindex"
      :dw-list="dwList"
      :is-fire-warn="fireFlag && slxxId!==null"
    />
    <!-- 语音对话 end -->
    <!-- 演示介绍 start-->
    <introduce
      ref="Introduce"
      class="monitor_npl-wrap o-zindex"
    />
    <!-- 演示介绍 end-->
    <!-- 火灾报警 start -->
    <div v-if="hjxxList.length>0">
      <FireWarn
        v-show="fireFlag && slxxId"
        ref="FireWarn"
        :key="slxxId"
        class="monitor_fireWarn-wrap"
        :slxx-id="slxxId"
        :hjxx-list="hjxxList"
        @updateSlxxId="updateSlxxId"
        @handleCancleOme="handleCancleOme"
      />
    </div>
    <div
      class="o-flicker"
      :class="{
        'o-flicker--open': omeMusicStatus==='open'
      }"
    >
      <div class="o-flicker__item o-flicker__item--top" />
      <div class="o-flicker__item o-flicker__item--bottom" />
    </div>
    <audio
      id="bjs"
      ref="omeMusic"
      loop="loop"
      muted="muted"
      class="o-omen-audio"
      :src="$config.omenMusicSrc.warning"
    />
    <!-- 火灾报警 end -->

    <!-- 左侧图表 Start -->
    <transition
      appear
      :duration="{ enter: 1000, leave:1400 }"
      name="animate__animated animate__bounceInLeft"
      enter-active-class="animate__animated animate__bounceInLeft"
      leave-active-class="animate__animated animate__bounceOutLeft"
    >
      <chart-left-box
        v-show="isShow"
        :dw-list="dwList"
        :active-monitoring-room="activeDwId"
        class="monitor_wrap monitor_wrap--left"
        :class="introduction.left.style"
      />
    </transition>
    <!-- 左侧图表 End -->

    <!-- 右侧图表 Start -->
    <transition
      appear
      :duration="{ enter: 1000, leave:1400 }"
      name="animate__animated animate__bounceInRight"
      enter-active-class="animate__animated animate__bounceInRight"
      leave-active-class="animate__animated animate__bounceOutRight"
    >
      <chart-right-box
        v-show="isShow && !showDetail"
        :dw-list="dwList"
        :active-monitoring-room="activeDwId"
        class="monitor_wrap monitor_wrap--right"
        :class="introduction.right.style"
      />
    </transition>
    <!-- 右侧图表 End -->

    <!-- 底部图表 Start -->
    <transition
      v-if="!showGzsbjl"
      appear
      :duration="{ enter: 1200, leave:2800 }"
      name="animate__animated animate__bounceInBottom"
      enter-active-class="animate__animated animate__bounceInBottom"
      leave-active-class="animate__animated animate__bounceOutBottom"
    >
      <Jrfxsssj
        v-show="isShow && !showDetail && !showGzsbjl"
        :active-monitoring-room="activeDwId"
        class="monitor-bfooter"
        :class="introduction.bottom.style"
      />
    </transition>
    <transition
      v-if="showGzsbjl"
      appear
      :duration="{ enter: 1200, leave:2800 }"
      name="animate__animated animate__bounceInBottom"
      enter-active-class="animate__animated animate__bounceInBottom"
      leave-active-class="animate__animated animate__bounceOutBottom"
    >
      <chartGzsbBottom
        v-show="isShow && !showDetail && showGzsbjl"
        :active-monitoring-room="activeDwId"
        :active-jzw="activeJzwId"
        class="monitor-bfooter"
        :class="introduction.bottom.style"
      />
    </transition>
    <!-- 底部图表 End -->

    <!-- 顶部信息 Start -->
    <!-- 详情顶部不再展示 -->
    <transition
      appear
      :duration="{ enter: 1200, leave:2800 }"
      name="animate__animated animate__bounceInDown"
      enter-active-class="animate__animated animate__bounceInDown"
      leave-active-class="animate__animated animate__bounceOutUp"
    >
      <ChartDetailTopT
        v-show="isShow"
        :code="activeDwId"
        class="monitor-top"
        :class="introduction.top.style"
      />
    </transition>
    <!-- 顶部信息 End -->

    <!-- 左侧图表 Start -->
    <transition
      appear
      :duration="{ enter: 1000, leave:1400 }"
      name="animate__animated animate__bounceInLeft"
      enter-active-class="animate__animated animate__bounceInLeft"
      leave-active-class="animate__animated animate__bounceOutLeft"
    >
      <ChartDetailLeft
        v-if="false"
        :dw-list="dwList"
        :active-monitoring-room="activeDwId"
        class="monitor_wrap monitor_wrap--left"
        :class="introduction.left.style"
      />
    </transition>
    <!-- 左侧图表 End -->

    <!-- 右侧图表 Start -->
    <transition
      appear
      :duration="{ enter: 1000, leave:1400 }"
      name="animate__animated animate__bounceInRight"
      enter-active-class="animate__animated animate__bounceInRight"
      leave-active-class="animate__animated animate__bounceOutRight"
    >
      <ChartDetailRight
        v-show="isShow && showDetail"
        :code="activeDwId"
        class="monitor_wrap monitor_wrap--right"
        :class="introduction.right.style"
      />
    </transition>
    <!-- 右侧图表 End -->

    <!-- 底部信息 Start -->
    <transition
      appear
      :duration="{ enter: 1200, leave:2800 }"
      name="animate__animated animate__bounceInUp"
      enter-active-class="animate__animated animate__bounceInUp"
      leave-active-class="animate__animated animate__bounceOutDown"
    >
      <chartDetailBottom
        v-if="isShow && showDetail"
        :active-monitoring-room="activeDwId"
        :active-jzw="activeJzwId"
        class="monitor-bfooter"
        :class="introduction.bottom.style"
      />
    </transition>
    <!-- 底部信息 End -->
  </div>
</template>

<script>
import TMap from './threeMap/ThreeMap.vue';
// import ScreenTitle from './components/ScreenTitle.vue';
import CenterBox from './components/centerBox.vue';
import BMap from './components/BMap.vue';
import Npl from '../virtual/npl/npl-index.vue';
import FireWarn from './fireWarn/fireWarnTJ.vue';
import ChartLeftBox from './components/leftBox-tj.vue';
import ChartRightBox from './components/rightBox.vue';
// import ChartDetailTop from './detail/topBox.vue';
import ChartDetailTopT from './detail/topBoxT.vue';
import ChartDetailLeft from './detail/leftBox.vue';
import ChartDetailRight from '../virtual/chart/rightBox.vue';
import chartDetailBottom from './detail/bottomBox.vue';
import chartGzsbBottom from './detail/bottomGzsbwx.vue';
import EventBus from './../../event-bus';
import { monitorService } from './monitor.service';
import SubTitle from './components/SubTitle.vue';
// import ChartBottomBox from './components/bottomBox.vue';
import Jrfxsssj from './components/jrfxsssj.vue';
import introduceJs from '@/features/introuduce/introduce.js';
import introduce from '@/features/introuduce/index.vue';
import config from '@/config.js';
import { queryEnvDetail, getEnvInfo } from '@/features/feature.common.service';
// import webVideoCtrl from '@/features/introuduce/webVideoCtrl.js';

const { wsApi } = config.ctx;
export default {
  name: 'MonitorVue',
  components: {
    TMap,
    SubTitle,
    Jrfxsssj,
    // ScreenTitle,
    CenterBox,
    BMap,
    Npl,
    FireWarn,
    ChartLeftBox,
    ChartRightBox,
    // ChartDetailTop,
    ChartDetailLeft,
    ChartDetailRight,
    chartDetailBottom,
    chartGzsbBottom,
    ChartDetailTopT,
    introduce
    // ChartBottomBox,
  },
  data () {
    return {
      modelLoadIcon: 'el-icon-loading',
      screenTitle: '消防数字哨兵',
      fireFlag: true, // 火灾警报 控制显示或者隐藏火警页面
      isBmap: true,
      isTmap: true,
      num: 1,
      omeMusicStatus: 'close', // 警报声音开关
      showDetail: false, // 显示联网单位详情
      showGzsbjl: false, // 显示故障设备记录
      isShow: true, // 是否隐藏所有报表
      activeJzwId: null,
      // 单位数据
      activeDwId: null,
      dwList: [
      ],
      sbzt: null, // 今日设备状态
      slxxId: null, // 当前选择的火灾主键
      hjxxList: [], // 发生火灾的全部列表
      isFilterYxhjList: false, // 控制火警定时查询是否跳过筛选当前单位存在的火灾(包含未确认)  true:跳过查询  false:正常查询
      dwId: null,
      introduction: {
        left: {
          style: []
        },
        right: {
          style: []
        },
        lwzt: {
          style: []
        },
        bottom: {
          style: []
        },
        top: {
          style: []
        }
      },
      LWDW_TITLE_NAME: config.app.name,
      websocket: null,
      seesionId: null,
      ygtsFla: true,
      notifyPromise: Promise.resolve(),
      isFullScreen: false, // 初始化全屏状态为false
      checkFullScreenInterval: null, // 检查全屏状态的定时器
      apiMessage: '' // 接口状态信息返回结果 不会弹窗，会显示在左上角显示后会隐藏
    };
  },
  computed: {
    // 用户信息
    currentUser () {
      return this.$HMStoreService.getUserinfo();
    }
  },
  methods: {
    init () {
      const _this = this;
      this.queryJqrjk();
      this.clock = setInterval(function () {
        _this.queryJqrjk();
      }, 1 * 60 * 1000);
      this.queryHjxx();
      this.getEnvDetail();
      this.getEnvInfo();
      this.clock2 = setInterval(this.queryHjxx, 3 * 1000);
    },
    getEnvDetail () {
      queryEnvDetail().then(resp => {
        console.log(resp.data);
        resp.data.forEach(item => {
          // console.log('item', item);
          if (item.envId === 'SCREEN_HZ_IMG') {
            sessionStorage.setItem('SCREEN_HZ_IMG', item.envValue);
          }
        });
      });
    },
    getEnvInfo () {
      const ssdw = this.currentUser.capacity.deptCode;
      // console.log('ssdw', ssdw, this.currentUser.capacity.capacityName);
      getEnvInfo(ssdw, 'LWDW_TITLE_NAME')
        .then(resp => {
          console.log(resp.data.envValue);
          this.LWDW_TITLE_NAME = resp.data.envValue;
        });
    },
    // 机器人监控室
    queryDqsbzt () {
      monitorService.queryDqsbzt().then(resp => {
        this.sbzt = resp.data;
      });
    },
    queryJqrjk () {
      monitorService.queryJqrjk().then(resp => {
        this.dwList = resp.data;
        // this.dwList.forEach(item => {
        //   // TODO 暂时写死坐标
        //   if (item.name.includes('物资大夏')) {
        //     item.lng = 113.285378; // 地理经度
        //     item.lat = 23.143544;// 地理纬度
        //   }
        //   if (item.name.includes('鸿蒙')) {
        //     item.lng = 113.375879; // 地理经度
        //     item.lat = 23.103363;// 地理纬度
        //   }
        // });
      });
    },
    changeDw (dwId) {
      const dw = this.dwList.find(el => el.code === dwId);
      if (this.isBmap) {
        this.$refs.BMap.changeDw(dw);
      } else if (this.isTmap) {
        this.$refs.TMap.changeDw(dw);
      } else {
        this.$refs.centerBox.changeDw(dw);
      }

      this.changeDetail(true, dwId);
    },
    changeDetail (flag, dwId, jzwId) {
      this.showDetail = flag;
      this.activeDwId = dwId;
      this.activeJzwId = jzwId;
    },
    /**
     * @description: 关闭报警声音
     * @return {*}
     */
    handleCancleOme (closeStatus = true, showMessage = false) {
      if (closeStatus) {
        this.omeMusicStatus = 'close';
      }
      this.$refs.omeMusic.pause();
      this.$refs.omeMusic.currentTime = 0.0;
      if (showMessage) {
        this.$message({
          message: '消音成功',
          type: 'success',
          duration: 1500
        });
      }
    },
    /**
     * @description: 开启声音报警
     * @return {*}
     */
    handleOpenOme () {
      const _this = this;
      const h = _this.$createElement;
      if (_this.omeMusicStatus === 'close') {
        // 开启
        _this.$refs.omeMusic.play().then(() => {
          // autoplay success
          _this.omeMusicStatus = 'open';
        }).catch(() => {
          _this.$alert('提示', {
            title: '提示',
            message: h('div', [
              h('p', '浏览器默认不允许播放音频，请前往配置以便后续默认支持音频播放，否则仅运行本次播放！'),
              h('a', { attrs: { class: 'u-text-primary u-pointer u-pd-t-4 u-inline-block', href: process.env.BASE_URL + 'omeSetIntro.html', target: '_blank' } }, '配置说明')
            ]),
            confirmButtonText: '本次允许',
            type: 'warning'
          })
            .then(() => {
              _this.$refs.omeMusic.play();
              _this.omeMusicStatus = 'open';
            }).catch(() => {
            });
        });
      } else {
        // 从头开始播放
        _this.$refs.omeMusic.currentTime = 0.0;
      }
    },
    handleOpenHj () {
      // 数字键4
      EventBus.$emit('controlShowHj', true, false);
    },
    handleOHj (dwId) {
      // this.isFilterYxhjList = true;
      this.dwId = dwId;
    },
    // 当前有效火警列表
    queryHjxx () {
      monitorService.getHjxx('').then(resp => {
        let hjxxList = resp.data;
        // console.log('=======查询所有火警情况========queryHjxx', hjxxList);
        // 如果火灾列表为空，则关闭声音
        if ((hjxxList && hjxxList.length === 0)) {
          this.handleCancleOme();
          this.slxxId = null;
          // 并且重置当前火列表
          this.hjxxList = [];
        }

        if (hjxxList && hjxxList.length > 0) {
          const zsHjxxList = [];

          hjxxList.forEach(item => {
            if (item.ssdw === this.dwId) {
              console.log('筛选danwei火灾', this.dwId);
              if ((item.xxqr === '2' && item.xnjqrbz === 'N') || (item.xnjqrbz === 'Y')) {
                // console.log('筛选真实火灾已确认火灾或虚拟机器人的火灾');
                zsHjxxList.push(item);
              }
            } else {
              if ((item.xxqr === '2' && item.xnjqrbz === 'N') || (item.xnjqrbz === 'Y')) {
                // console.log('筛选真实火灾已确认火灾或虚拟机器人的火灾');
                zsHjxxList.push(item);
              }
            }
          });
          hjxxList = zsHjxxList;
          // console.log('=========筛选后火灾list==========', zsHjxxList);
        }

        // 如果火灾列表为空，则关闭声音
        if ((hjxxList && hjxxList.length === 0)) {
          this.handleCancleOme();
          this.slxxId = null;
          // 并且重置当前火列表
          this.hjxxList = [];
        }

        // if (hjxxList && hjxxList.length > 0) {
        //   const zsHjxxList = [];
        //   console.log('是否筛选当前单位存在的火灾(包含未确认)==============', this.isFilterYxhjList);
        //   hjxxList.forEach(item => {
        //     // 单位监控列表里有火灾情况时，点击火灾图标，筛选出该单位的所有火灾情况(包含未确认)
        //     console.log('筛选当前单位存在的火灾(包含未确认)');
        //     if (this.isFilterYxhjList) {
        //       if (item.ssdw === this.dwId) {
        //         zsHjxxList.push(item);
        //       }
        //     } else {
        //       // 筛选真实火灾已确认火灾或虚拟机器人的火灾
        //       if ((item.xxqr === '2' && item.xnjqrbz === 'N') || (item.xnjqrbz === 'Y')) {
        //         console.log('筛选真实火灾已确认火灾或虚拟机器人的火灾');
        //         zsHjxxList.push(item);
        //       }
        //     }
        //   });
        //   console.log('=========筛选后火灾list==========', zsHjxxList);
        //   hjxxList = zsHjxxList;
        // }

        if (hjxxList && this.hjxxList.length !== hjxxList.length) {
          // 重置机器人监控室的单位列表
          this.queryJqrjk();
        }

        // 如果旧记录长度为0，并且新纪录长度大于0，则开启火灾并且播放声音
        if (!this.$refs.Introduce.introduceAll && this.hjxxList.length === 0 && hjxxList && hjxxList.length > 0) {
          // console.log('=========如果旧记录长度为0，并且新纪录长度大于0，则开启火灾并且播放声音==========', hjxxList);
          // 开启火灾并且播放声音
          EventBus.$emit('controlShowHj', true, true);
          this.slxxId = hjxxList[0].id;
        } else if (this.hjxxList.length > 0 && hjxxList && hjxxList.length > this.hjxxList.length) {
          // 如果新记录长度大于旧记录长度，且旧记录长度大于0，则自动跳转至火警界面
          this.slxxId = hjxxList[0].id;
          EventBus.$emit('controlShowHj', true, true);
        };

        // 刷新火灾列表，如果当前选择的火灾没在列表里面，则选择另外的最新火灾
        if (!this.$refs.Introduce.introduceAll && hjxxList && hjxxList.length > 0) {
          // console.log('=========刷新火灾列表，如果当前选择的火灾没在列表里面，则选择另外的最新火灾==========', hjxxList);
          this.hjxxList = hjxxList;
          // 设置最先的一条火警
          const slxxIdOld = this.slxxId;
          let lastHj = hjxxList.find(hj => hj.id === this.slxxId);
          const slxxId = lastHj ? lastHj.id : hjxxList[0].id;
          this.slxxId = slxxId;

          // 如果受理信息发生变化，则重置num
          if (slxxIdOld !== this.slxxId) {
            this.num = 1;
          }
          // 跳转疏散图
          lastHj = hjxxList.find(hj => hj.id === this.slxxId);
          if (lastHj.xxqr && lastHj.xxqr === '2' && this.num <= 1) {
            console.log('触发跳转疏散图', lastHj.xxqr);
            setTimeout(() => {
              EventBus.$emit('hj_qrhj');
            }, 2000);
            this.num = this.num + 1;
          }
        }
      }).catch(error => {
        if (error.response) {
          console.log('服务异常:', error.response);
        } else if (error.request) {
          console.log('网络异常:', error.request);
        } else {
          console.log('请求异常:', error.message);
        }
      });
    },
    updateSlxxId (slxxId) {
      console.log('更改当前火灾主键');
      this.slxxId = slxxId;
    },
    transformUint8ArrayToBase64 (array) {
      let binary = '';
      for (var len = array.byteLength, i = 0; i < len; i++) {
        binary += String.fromCharCode(array[i]);
      }
      return window.btoa(binary).replace(/=/g, '');
    },
    randomSessionId () {
      const ua = new Uint8Array(20);
      new DataView(ua.buffer).setUint32(0, Math.floor(+new Date() / 1000));
      const crypto = window.crypto || window.msCrypto;
      if (crypto) {
        crypto.getRandomValues(ua.subarray(4, 20));
      }
      return (
        this.transformUint8ArrayToBase64(ua)
          .replace(/\+/g, '-')
          .replace(/\//g, '-')
      );
    },

    websocketInit () {
      const hasToken = this.$HMCookies.get(config.localTokenName) || false;
      const _this = this;
      if (hasToken) {
        const wsprocotol = window.location.protocol === 'https:' ? 'wss://' : 'ws://';
        let apiContext = '';
        if (!wsApi) {
          apiContext = '/api/iff';
        }

        console.log(`${wsprocotol}${wsApi || window.location.host}${apiContext}/iff/socket/ssjk/`);
        this.websocket = new WebSocket(`${wsprocotol}${wsApi || window.location.host}${apiContext}/iff/socket/ssjk/${this.seesionId}?token=${hasToken}`);
        // this.websocket = new WebSocket(`ws://localhost:18070/iff/iff/socket/ssjk/${this.seesionId}?token=${hasToken}`);
        // this.websocket = new WebSocket(`wss://iff-rpa-test.hongmeng.cloud/api/iff/iff/socket/ssjk/${this.seesionId}?token=${hasToken}`);
        this.websocket.onopen = () => {
          _this.socketOpen = true;
          console.log('Socket连接成功');
          this.websocket.send(JSON.stringify({ layout: 'Dplayout' }));
        };
        this.websocket.onmessage = (res) => {
          const resdata = JSON.parse(res.data);
          if (resdata.key === 'cgcfts') {
            this.cgcfts(resdata.values);
          } else if (resdata.key === 'ygcs') {
            this.ygcs(resdata.values);
          } else if (resdata.key === 'ygts') {
            this.ygtsFla = resdata.values.id !== sessionStorage.getItem(resdata.values.ssdw);
            if (resdata.values !== null && this.ygtsFla) {
              this.ygts(resdata.values);
            }
          }
        };
        this.websocket.onerror = () => {
          _this.socketOpen = false;
          console.log('Socket连接发生错误，将在5秒后重试...');
          setTimeout(() => {
            if (!this.socketOpen) {
              _this.socketOpen = true;
              this.websocketInit();
            }
          }, 5000);
        };
        this.websocket.onclose = () => {
          _this.socketOpen = false;
          console.log('Socket连接已关闭，将在5秒后重试...');
          setTimeout(() => {
            if (!this.socketOpen) {
              _this.socketOpen = true;
              this.websocketInit();
            }
          }, 5000);
        };
      }
    },
    // 单位查岗
    handleCg (_ssdw) {
      const dwmc = this.dwList.filter(item => item.botid === _ssdw);
      console.log(dwmc);
      this.$message({
        showClose: true,
        duration: 3000,
        type: 'success',
        message: '已对【' + dwmc[0].name + '】发起查岗！'
      });
      this.websocket.send(JSON.stringify({ cg: { id: _ssdw, code: _ssdw } }));
    },

    /**
     * 所有单位查岗
     */
    handleAllCg () {
      console.log('发起全部查岗');
      this.$message({
        showClose: true,
        duration: 3000,
        type: 'success',
        message: '已对所有在线单位发起查岗！'
      });
      this.websocket.send(JSON.stringify({ allcg: { code: 'code' } }));
    },
    /**
     * 查岗重复提示
     */
    cgcfts (_item) {
      const canter = '(' + _item.ssdwMc + ') 单位已查岗,待应岗中!,发起人：' + _item.fqrMc + ',查岗时间：' + _item.fqsjStr;
      this.$message({
        showClose: true,
        duration: 5000,
        message: canter
      });
    },

    /**
     * 应岗提醒
     */
    ygts (_item) {
      console.log(_item);
      sessionStorage.setItem(_item.ssdw, _item.id);
      const canter = '单位应岗成功!,当前值班人员：' + _item.zbrymc;
      this.notifyPromise = this.notifyPromise.then(() => {
        this.$notify({
          title: _item.ssdwMc + '(应岗成功)!',
          type: 'success',
          message: canter,
          position: 'bottom-right',
          duration: 10000,
          offset: 150
        });
      });
    },
    ygcs (_item) {
      this.warining(this.$refs.cgcsMusic);
      const canter = '请注意该单位查岗时间点(' + _item.fqsjStr + ') 未应岗,当前值班人员/联系电话:' + _item.zbrymc + '/' + _item.lxdh;
      this.notifyPromise = this.notifyPromise.then(() => {
        this.$notify({
          title: _item.ssdwMc + '(应岗超时)!',
          type: 'warning',
          message: canter,
          position: 'bottom-right',
          offset: 150,
          duration: 1000 * 60 * 1,
          onClose: action => {
            this.cancelWarning(this.$refs.cgcsMusic);
          }
        });
      });
    },
    // 打开全屏
    goFullScreen () {
      const element = document.documentElement;

      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) { /* Firefox */
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) { /* IE/Edge */
        element.msRequestFullscreen();
      }
    },
    checkFullScreen () {
      // 检查窗口尺寸是否与屏幕尺寸相匹配
      this.isFullScreen = window.innerHeight === screen.height;
    },
    showApiMessage (message) {
      this.apiMessage = message;
      const newestShowTime = new Date().getTime();
      setTimeout(() => {
        // 当前时间没有大于3秒，则不清空
        if (new Date().getTime() - newestShowTime < 3000) {
          return;
        }
        this.apiMessage = '';
      }, 3000);
    }

  },
  watch: {
    slxxId (val) {
      console.log('slxxId被触发修改', val);
      if (val) {
        this.$refs.Introduce.introduceAll = false;
      }
    }
  },
  mounted () {
    this.seesionId = this.randomSessionId();
    window.addEventListener('beforeunload', function (event) {
      console.log('页面刷新清除cad缓存文件');
      // 清除key 为CAD_URL:开头的数据
      for (const key in sessionStorage) {
        if (key.indexOf('CAD_URL:') === 0) {
          sessionStorage.removeItem(key);
        }
      }
    });
    this.websocketInit();
    // 切换不同版本
    this.isBmap = this.$route.query.isBmap !== 'false';
    this.isTmap = this.$route.query.isTmap === 'true';

    this.init();
    // 增加快捷键设置y
    document.addEventListener('keypress', (event) => {
      if (event.shiftKey && event.code === 'Digit1') {
        // 数字键1
        this.isShow = !this.isShow;
      }
      if (event.shiftKey && event.code === 'Digit1') {
        this.$refs.Npl.resetNpl();
      }

      if (event.shiftKey && event.code === 'Digit2') {
        // 数字键2
        this.$refs.Npl.listen();
      }
      if (event.shiftKey && event.code === 'Digit3') {
        // 数字键3
        this.$refs.Npl.change();
      }
      if (event.shiftKey && event.code === 'Digit4') {
        // 数字键4
        EventBus.$emit('controlShowHj', !this.fireFlag);
      }
    });

    // 监听EventBus触发事件
    /* 调用方式：
      1、 EventBus.$emit('nplListen', params);
      2、window.EventBus.$emit('nplListen', params);
    */
    EventBus.$on('nplListen', param => {
      console.log('EventBus事件触发，入参：', param);
      this.$refs.Npl.listen();
    });

    EventBus.$on('ModelLoaded', param => {
      console.log(param);
      this.modelLoadIcon = param === true ? 'el-icon-check' : 'el-icon-close';
    });

    EventBus.$on('Dpdwcg', ssdw => {
      console.log(ssdw);
      this.handleCg(ssdw);
    });
    EventBus.$on('DpYjcg', data => {
      this.handleAllCg();
    });

    EventBus.$on('DpGzsbjl', data => {
      this.showGzsbjl = !this.showGzsbjl;
    });

    EventBus.$on('apiMessage', data => {
      this.showApiMessage(data);
    });

    // 隐藏火警
    EventBus.$on('controlShowHj', (param, controlOme = false) => {
      // pram: true 显示火警
      this.fireFlag = param;
      // 控制声音
      if (this.fireFlag && controlOme) {
        this.handleOpenOme();
      } else if (!this.fireFlag && controlOme) {
        this.handleCancleOme();
      }
    });
  },
  created () {
    const _this = this;

    console.log('当前用户信息', this.currentUser.id);
    sessionStorage.setItem('loginUser', this.currentUser.id);
    EventBus.$on('introduce_zt_start', function (data) {
      console.log('introduce_zt_start');

      introduceJs.activeElement(_this.introduction, data, EventBus);
      console.log(_this.introduction);
    });
    EventBus.$on('introduce_zt_stop', function (data) {
      introduceJs.clearStyle(_this.introduction, EventBus);
    });

    // 监听火灾
    EventBus.$on('hzInfo', function (hjxxList) {
      if (hjxxList && hjxxList.length > 0) {
        // const slxxId = hjxxList[0].id;
        // if (!_this.slxxId) _this.handleOpenOme();
        // _this.slxxId = slxxId;
      } else {
        _this.slxxId = null;
        _this.handleCancleOme();
      }
    });

    this.checkFullScreen(); // 检查初始全屏状态
    this.checkFullScreenInterval = setInterval(this.checkFullScreen, 500); // 每检查一次
  },

  destroyed () {
    clearInterval(this.clock);
    clearInterval(this.clock2);
    this.websocket.close();

    clearInterval(this.checkFullScreenInterval); // 清除定时器
  }
};
</script>

<style scoped lang="scss">
@import '../introuduce/introduce.css';

$--header-h: 70px;
$--header-h2: 190px;
$--chart-left-w: 360px; // 21.7%
$--chart-right-w: 399px; // 25.3%
$--bottom-w: 1087px;

.monitor{
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: auto;
}

.monitor-header{
  position: absolute;
  top: 0;
  left: 0;
  z-index: 14;
  width: 100%;
  height: 100px;
  text-align: center;
  background: url('/images/screen/header-bg-new.png') no-repeat center center;
  background-size: cover;

  display: flex;
  justify-content: center;
  /* &::before{
    content: "";
    display: inline-block;
    width: 468px;
    height: 40px;
    background: url('/images/screen/title.svg') no-repeat center center;
  } */
}

.monitor-BMap{
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

.monitor_npl-wrap{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;

  &.o-zindex{
    top: $--header-h;
    z-index: 1114;
  }

}

.monitor_fireWarn-wrap{
  position: absolute;
  top: $--header-h;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1200;
}

.monitor_wrap{
  position: absolute;
  z-index: 1112;
  box-sizing: border-box;
  width: 362px;
  height: 954px;
  overflow: hidden;
  // padding: 28px 20px 28px 14px;
  color: #fff;
  background: url(/images/monitor/rightBox.png) no-repeat 0 0 / cover;
  // background-image: linear-gradient(
  //   269deg,
  //   rgba(14, 33, 70, 0.24) 0%,
  //   rgba(12, 29, 63, 0.64) 10%,
  //   rgba(0, 9, 28, 0.72) 99%
  // );

  &--left{
    top: $--header-h+20px;
    bottom: 0;
    left: 17px;
    width: $--chart-left-w;
  }

  &--right{
    top: $--header-h + 20px;
    right: 21px;
    bottom: 0;
    width: $--chart-right-w  - 16px;
  }
}

.monitor-top{
  position: absolute;
  top: $--header-h + 17px;
  left: $--chart-left-w + 60;
  z-index: 14;
  box-sizing: border-box;
  width: 939px;
}

.monitor-bfooter{
  position: absolute;
  bottom: 38px;
  left: $--chart-left-w + 45;
  z-index: 14;
  box-sizing: border-box;
  width: $--bottom-w;
  height: 300px;
  color: #fff;
}

@keyframes redPulse {
  from {
    box-shadow: 0 0 40px 20px #d84f28;
    background-color: #d84f28;
  }

  25% {
    box-shadow: 0 0 90px 40px #e0461b;
    background-color: #e0461b;
  }

  50% {
    box-shadow: 0 0 140px 60px #e33100;;
    background-color: #e33100;
  }

  75% {
    box-shadow: 0 0 90px 40px #e0461b;
    background-color: #e0461b;
  }

  to {
    box-shadow: 0 0 40px 20px #d84f28;
    background-color: #d84f28;
  }
}

.o-flicker__item{
  display: none;
  position: absolute;
  right: 0;
  left: 0;
  z-index: 9999;
  height: 0;

  &--top{
    top: 0;
  }

  &--bottom{
    bottom: -20px;
  }
}

.o-flicker--open .o-flicker__item{
  display: block;
  animation: redPulse 2.5s infinite;
}

.npl-wrap {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  width: 1920px;
}

.monitor-header-title{
  width: 420px;
  height: 43px;
  font-family: HYYaKuHei-85J;
  font-weight: 85J;
  font-size: 36px;
  color: #00FCFF;
  letter-spacing: 5.4px;
  text-align: center;
  margin-top: 25px;
  user-select: none;
}
.breathing-svg {
  animation: breathing 1s infinite alternate;
}
@keyframes breathing {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

// 全屏样式
.fullscreen-button-container {
}

.fullscreen-button{
  position: relative;
  left: 0px;
  top: -75px;
  background-color: transparent !important; /* 设置背景为透明 */
  #border: none !important; /* 移除边框 */
  color: #fff; /* 设置文字颜色，根据需要自行调整 */

}
.fullscreen-button:hover {
  /* 鼠标悬停的样式效果 */
  transform: scale(1.1); /* 使按钮稍微放大 */
  transition: transform 0.3s ease-in-out;
}

</style>
