/*
 * @Author: cxb
 * @Date: 2022-10-18 15:52:03
 * @LastEditors: zxf
 * @LastEditTime: 2023-12-26 10:27:31
 * @LastEditors: cxb chenxiaobiao@hongmeng-info.com
 * @LastEditTime: 2023-12-20 20:34:21
 * @Description: file content
 */

import axios from 'axios';
import config from '@/config';

const { iffApi } = config.ctx;

export const monitorService = {

  // 监控室
  queryJqrjk: () => { return axios.get(`${iffApi}/dp/jqrJks`); },

  // 当日 设备状态信息
  getXfsbByToday: () => { return axios.get(`${iffApi}/dp/indx/xfsbTjByToday`); },

  // 月度 设备状态信息
  getXfsbByMonth: () => { return axios.get(`${iffApi}/dp/indx/xfsbTjByMonth`); },

  // 消防设备
  queryXfsb: (lwdw) => { return axios.get(`${iffApi}/dp/xfsb/${lwdw}`); },

  // 消防维保情况
  queryXfwbqk: (lwdw) => { return axios.get(`${iffApi}/dp/xfwbqk/${lwdw}`); },

  // 建筑物概述
  queryJzw: (lwdw, jzwId) => { return axios.get(`${iffApi}/dp/jzw/${lwdw}/${jzwId || ''}`); },

  // 值班人员
  queryZbry: (lwdw) => { return axios.get(`${iffApi}/dp/jzzb/${lwdw}`); },

  // 火警信息
  queryHjxx: (lwdw) => { return axios.get(`${iffApi}/dp/hjxx/${lwdw}`); },

  // 当日告警信息
  getHjxxByToday: (lwdw) => { return axios.get(`${iffApi}/dp/indx/hjxxTjByToday/${lwdw}`); },

  // 统计最近6个月的火警信息
  queryHjxxByMonths: (lwdw) => { return axios.get(`${iffApi}/dp/hjxx/listByMonths`); },

  // 统计最近6个月的火警信息
  queryTotalHjxxByMonths: (lwdw) => { return axios.get(`${iffApi}/dp/hjxx/totalByMonths`); },

  // 获取是否存在报警情况信息
  getHjxx: (lwdw) => { return axios.get(`${iffApi}/dp/hjxx/now/${lwdw}`); },
  // 获取有效报警情况信息
  getYxHjxx: (lwdw) => { return axios.get(`${iffApi}/dp/yxhjxx/now/${lwdw}`); },

  // 月度监控报告统计查询
  queryReport: () => { return axios.get(`${iffApi}/dp/ydjkbg`); },

  queryEnvDetail: (params) => { return axios.get(`${iffApi}/env/queryEnvDetail`, { params: params }); },

  getVideoHost: () => { return axios.get(`${iffApi}/video/getVideoHost`); },

  // 统计信息
  queryTjxx: () => { return axios.get(`${iffApi}/dqhz/tjxx`); },

  queryTjxxs: (ssdw) => { return axios.get(`${iffApi}/dqhz/tjxxs/${ssdw}`); },
  // 今日设备状态
  queryDqsbzt: () => { return axios.get(`${iffApi}/dqhz/dqsbzt`); },

  // 实时列表数据
  queryYxbgSssj: () => { return axios.get(`${iffApi}/dp/yxbg/sssj`); },

 // 故障设备维修情况
 querySbWxqk: (radio) => { return axios.get(`${iffApi}/dp/gzsb/wxqk/${radio}`); }

};
